import { render, staticRenderFns } from "./SearchTrades.vue?vue&type=template&id=f87a5720&scoped=true&"
import script from "./SearchTrades.vue?vue&type=script&lang=js&"
export * from "./SearchTrades.vue?vue&type=script&lang=js&"
import style0 from "./SearchTrades.vue?vue&type=style&index=0&lang=css&"
import style1 from "./SearchTrades.vue?vue&type=style&index=1&lang=scss&"
import style2 from "./SearchTrades.vue?vue&type=style&index=2&id=f87a5720&lang=scss&scoped=true&"


/* normalize component */
import normalizer from "!../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "f87a5720",
  null
  
)

export default component.exports