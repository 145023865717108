<template>
  <div>
    {{ /*view selected trade modal*/ }}
    <b-modal
      id="tradeModal"
      v-model="reverseEntryTradeModal"
      :title="$t('equity_bond.titles.reverse_entry')"
      @hidden="resetTradeModal"
      size="xl"
      :ok-title="$t('equity_bond.modals.create_reversal_modal_ok')"
      :cancel-title="$t('equity_bond.modals.modal_cancel')"
      @ok="tradeOkOperation"
      :body-bg-variant="'light'"
      :header-bg-variant="'dark'"
      class="tradeModal"
      :no-enforce-focus="true"
      body-class="compact_form_modal"
      footer-class="compact_modal_footer"
      no-close-on-backdrop
    >
      <template #modal-header="{ close }">
        <h5 style="color: white;">
          {{ $t("equity_bond.titles.reverse_entry") }}
        </h5>
        <p v-if="!checkVerify">
          <b-icon icon="exclamation-circle-fill" variant="warning"></b-icon>
          <b style="color: white;">
            {{ $t("equity_bond.modals.trade_not_verified") }}
          </b>
        </p>

        <p v-else>
          <b-icon icon="bookmark-check-fill" variant="success"></b-icon>
          <b style="color: white;">
            {{ $t("equity_bond.modals.verified_trade") }}
          </b>
        </p>
      </template>

      <b-overlay :show="tradeModalLoading" rounded="sm" no-fade>
        <b-container>
          <validation-observer ref="tradeValidation">
            <b-form>
              <!-- /*******************************Order Details***************************************************************** */ -->
              <b-row>
                <b-card :title="$t('equity_bond.titles.order_details')">
                  <b-row>
                    <!-- order date-->
                    <b-col cols="6" xl="1" lg="1" md="3" sm="6">
                      <b-form-group
                        :label="$t('equity_bond.trade_form.order_date')"
                      >
                        <validation-provider
                          #default="{ errors }"
                          :name="$t('equity_bond.trade_form.order_date')"
                          rules="required"
                        >
                          <flat-pickr
                            :disabled="true"
                            ref="orderDate"
                            :placeholder="
                              $t('equity_bond.trade_form.order_date')
                            "
                            v-model="tradeForm.orderDate"
                            onClose="testClose"
                            class="form-control "
                            :config="dateConfig"
                            :state="errors.length > 0 ? false : null"
                          />
                          <small
                            class="text-danger compact_form-validation-message"
                            >{{ errors[0] }}</small
                          >
                        </validation-provider>
                      </b-form-group>
                    </b-col>
                    <!-- order time-->
                    <b-col cols="6" xl="1" lg="1" md="3" sm="6">
                      <b-form-group
                        :label="$t('equity_bond.trade_form.order_time')"
                      >
                        <validation-provider
                          #default="{ errors }"
                          :name="$t('equity_bond.trade_form.order_time')"
                          rules="required"
                        >
                          <cleave
                            :disabled="true"
                            ref="orderTime"
                            id="orderTime"
                            v-model="tradeForm.orderTime"
                            class="form-control "
                            :raw="false"
                            :options="cleaveOptions.time"
                            :placeholder="
                              $t('equity_bond.trade_form.order_time')
                            "
                            :state="errors.length > 0 ? false : null"
                          />
                          <small
                            class="text-danger compact_form-validation-message"
                            >{{ errors[0] }}</small
                          >
                        </validation-provider>
                      </b-form-group>
                    </b-col>
                    <!-- Order duration date -->
                    <b-col cols="6" xl="1" lg="1" md="3" sm="6">
                      <b-form-group
                        :label="
                          $t('equity_bond.trade_form.order_duration_date')
                        "
                        v-if="orderDurationInputsVisible"
                      >
                        <validation-provider
                          #default="{ errors }"
                          :name="
                            $t('equity_bond.trade_form.order_duration_date')
                          "
                        >
                          <flat-pickr
                            :placeholder="
                              $t('equity_bond.trade_form.order_duration_date')
                            "
                            v-model="tradeForm.orderDurationDate"
                            onClose="testClose"
                            class="form-control "
                            :config="dateConfig"
                            :state="errors.length > 0 ? false : null"
                            :disabled="true"
                            ref="orderDurationDate"
                          />
                          <small
                            class="text-danger compact_form-validation-message"
                            >{{ errors[0] }}</small
                          >
                        </validation-provider>
                      </b-form-group>
                    </b-col>
                    <!-- Order duration time -->
                    <b-col cols="6" xl="1" lg="1" md="3" sm="6">
                      <b-form-group
                        :label="
                          $t('equity_bond.trade_form.order_duration_time')
                        "
                        v-if="orderDurationInputsVisible"
                      >
                        <validation-provider
                          #default="{ errors }"
                          :name="
                            $t('equity_bond.trade_form.order_duration_time')
                          "
                        >
                          <cleave
                            id="orderDurationTime"
                            v-model="tradeForm.orderDurationTime"
                            class="form-control "
                            :raw="false"
                            :options="cleaveOptions.time"
                            :placeholder="
                              $t('equity_bond.trade_form.order_duration_time')
                            "
                            :state="errors.length > 0 ? false : null"
                            :disabled="true"
                            ref="orderDurationTime"
                          />

                          <small
                            class="text-danger compact_form-validation-message"
                            >{{ errors[0] }}</small
                          >
                        </validation-provider>
                      </b-form-group>
                    </b-col>
                    <!-- entry type -->
                    <b-col cols="6" xl="1" lg="1" md="3" sm="6">
                      <b-form-group
                        :label="$t('equity_bond.trade_form.entry_type')"
                      >
                        <validation-provider
                          #default="{ errors }"
                          :name="$t('equity_bond.trade_form.entry_type')"
                          rules="required"
                        >
                          <v-select
                            v-model="tradeForm.entryType"
                            :options="optEntryTypes"
                            :clearable="false"
                            :placeholder="
                              $t('equity_bond.trade_form.select_entry_type')
                            "
                            :reduce="(val) => val"
                            :state="errors.length > 0 ? false : null"
                            :disabled="true"
                            ref="entryType"
                          />
                          <small
                            class="text-danger compact_form-validation-message"
                            >{{ errors[0] }}</small
                          >
                        </validation-provider>
                      </b-form-group>
                    </b-col>
                    <!-- buy/sell -->
                    <b-col cols="6" xl="1" lg="1" md="3" sm="6">
                      <b-form-group
                        :label="$t('equity_bond.trade_form.buy_sell')"
                      >
                        <validation-provider
                          #default="{ errors }"
                          :name="$t('equity_bond.trade_form.buy_sell')"
                          rules="required"
                        >
                          <v-select
                            v-model="tradeForm.buySell"
                            :options="optbuySells"
                            :clearable="false"
                            :placeholder="
                              $t('equity_bond.trade_form.select_buy_sell')
                            "
                            :reduce="(val) => val"
                            :state="errors.length > 0 ? false : null"
                            :disabled="true"
                            ref="buySell"
                          />
                          <small
                            class="text-danger compact_form-validation-message"
                            >{{ errors[0] }}</small
                          >
                        </validation-provider>
                      </b-form-group>
                    </b-col>
                    <!-- order type-->
                    <b-col cols="6" xl="1" lg="1" md="3" sm="6">
                      <b-form-group
                        :label="$t('equity_bond.trade_form.order_type')"
                      >
                        <validation-provider
                          #default="{ errors }"
                          :name="$t('equity_bond.trade_form.order_type')"
                          rules="required"
                        >
                          <v-select
                            v-model="tradeForm.orderType"
                            :options="optOrderTypes"
                            :clearable="false"
                            :placeholder="
                              $t('equity_bond.trade_form.select_order_type')
                            "
                            :reduce="(val) => val"
                            :state="errors.length > 0 ? false : null"
                            :disabled="true"
                            ref="orderType"
                          />
                          <small
                            class="text-danger compact_form-validation-message"
                            >{{ errors[0] }}</small
                          >
                        </validation-provider>
                      </b-form-group>
                    </b-col>
                    <!-- time in force-->
                    <b-col cols="6" xl="1" lg="1" md="3" sm="6">
                      <b-form-group
                        :label="$t('equity_bond.trade_form.time_in_force')"
                      >
                        <validation-provider
                          #default="{ errors }"
                          :name="$t('equity_bond.trade_form.time_in_force')"
                          rules="required"
                        >
                          <v-select
                            v-model="tradeForm.timeInForce"
                            :options="optTimeInForces"
                            :clearable="false"
                            :placeholder="
                              $t('equity_bond.trade_form.select_time_in_force')
                            "
                            :reduce="(val) => val"
                            :state="errors.length > 0 ? false : null"
                            :disabled="true"
                            ref="timeInForce"
                          />
                          <small
                            class="text-danger compact_form-validation-message"
                            >{{ errors[0] }}</small
                          >
                        </validation-provider>
                      </b-form-group>
                    </b-col>
                    <!-- Stop Price -->
                    <b-col
                      cols="6"
                      xl="2"
                      lg="2"
                      md="3"
                      sm="6"
                      v-if="
                        tradeForm.orderType == 'Stop' ||
                          tradeForm.orderType == 'Trailing Stop'
                      "
                    >
                      <b-form-group
                        :label="$t('equity_bond.trade_form.stop_price')"
                      >
                        <validation-provider
                          #default="{ errors }"
                          :name="$t('equity_bond.trade_form.stop_price')"
                        >
                          <cleave
                            id="stopPrice"
                            :state="errors.length > 0 ? false : null"
                            v-model="tradeForm.stopPrice"
                            class="form-control "
                            :raw="true"
                            :options="cleave8DecimalOptions.number"
                            :placeholder="
                              $t('equity_bond.trade_form.stop_price')
                            "
                            :disabled="true"
                          />
                          <small class="text-danger ">{{ errors[0] }}</small>
                        </validation-provider>
                      </b-form-group>
                    </b-col>
                    <!-- Trailing Amount -->
                    <b-col
                      cols="6"
                      xl="2"
                      lg="2"
                      md="3"
                      sm="6"
                      v-if="tradeForm.orderType == 'Trailing Stop'"
                    >
                      <b-form-group
                        :label="$t('equity_bond.trade_form.trailing_amount')"
                      >
                        <validation-provider
                          #default="{ errors }"
                          :name="$t('equity_bond.trade_form.trailing_amount')"
                        >
                          <b-form-input
                            id="trailingAmount"
                            v-model="tradeForm.trailingAmount"
                            name="trailingAmount"
                            type="number"
                            :state="errors.length > 0 ? false : null"
                            :placeholder="
                              $t('equity_bond.trade_form.trailing_amount')
                            "
                            :disabled="true"
                          />
                          <small class="text-danger ">{{ errors[0] }}</small>
                        </validation-provider>
                      </b-form-group>
                    </b-col>
                  </b-row>
                  <!-- /******************************* OCO Orders***************************************************************** */ -->

                  <b-row>
                    <!-- enable connected OCO orders-->
                    <b-col cols="12" xl="2" lg="2" md="12" sm="12">
                      <b-form-group class="mt-1">
                        <validation-provider
                          #default="{ errors }"
                          name="enable OCO orders"
                        >
                          <b-form-checkbox
                            v-model="tradeForm.enableConnectedOCOOrders"
                            plain
                            :disabled="true"
                          >
                            {{ $t("equity_bond.trade_form.enable_oco") }}
                          </b-form-checkbox>
                          <small class="text-danger ">{{ errors[0] }}</small>
                        </validation-provider>
                      </b-form-group>
                    </b-col>
                    <!-- cpty profit limit & cpty stop loss stop & cpty stop loss limit -->

                    <!-- cpty Take Profit Limit -->
                    <b-col cols="6" xl="2" lg="2" md="4" sm="6">
                      <b-form-group
                        :label="
                          $t(
                            'fx_spot_forward_swap.trade_form.cpty_take_profit_limit'
                          )
                        "
                        v-if="tradeForm.enableConnectedOCOOrders"
                      >
                        <validation-provider
                          #default="{ errors }"
                          :name="
                            $t(
                              'fx_spot_forward_swap.trade_form.cpty_take_profit_limit'
                            )
                          "
                        >
                          <cleave
                            id="cptyTakeProfitLimit"
                            :state="errors.length > 0 ? false : null"
                            v-model="tradeForm.cptyTakeProfitLimit"
                            class="form-control"
                            :raw="true"
                            :options="cleave8DecimalOptions.number"
                            :placeholder="
                              $t(
                                'fx_spot_forward_swap.trade_form.cpty_take_profit_limit'
                              )
                            "
                            :disabled="true"
                          />

                          <small class="text-danger">{{ errors[0] }}</small>
                        </validation-provider>
                      </b-form-group>
                    </b-col>
                    <!-- cpty Stop Loss Stop -->
                    <b-col cols="6" xl="2" lg="2" md="4" sm="6">
                      <b-form-group
                        :label="
                          $t(
                            'fx_spot_forward_swap.trade_form.cpty_stop_loss_stop'
                          )
                        "
                        v-if="tradeForm.enableConnectedOCOOrders"
                      >
                        <validation-provider
                          #default="{ errors }"
                          :name="
                            $t(
                              'fx_spot_forward_swap.trade_form.cpty_stop_loss_stop'
                            )
                          "
                        >
                          <cleave
                            id="cptystopLossStop"
                            :state="errors.length > 0 ? false : null"
                            v-model="tradeForm.cptyStopLossStop"
                            class="form-control"
                            :raw="true"
                            :options="cleave8DecimalOptions.number"
                            :placeholder="
                              $t(
                                'fx_spot_forward_swap.trade_form.cpty_stop_loss_stop'
                              )
                            "
                            :disabled="true"
                          />
                          <small class="text-danger">{{ errors[0] }}</small>
                        </validation-provider>
                      </b-form-group>
                    </b-col>
                    <!-- cpty Stop Loss Limit -->
                    <b-col cols="6" xl="2" lg="2" md="4" sm="6">
                      <b-form-group
                        :label="
                          $t(
                            'fx_spot_forward_swap.trade_form.cpty_stop_loss_limit'
                          )
                        "
                        v-if="tradeForm.enableConnectedOCOOrders"
                      >
                        <validation-provider
                          #default="{ errors }"
                          :name="
                            $t(
                              'fx_spot_forward_swap.trade_form.cpty_stop_loss_limit'
                            )
                          "
                        >
                          <cleave
                            id="cptyStopLossLimit"
                            :state="errors.length > 0 ? false : null"
                            v-model="tradeForm.cptyStopLossLimit"
                            class="form-control"
                            :raw="true"
                            :options="cleave8DecimalOptions.number"
                            :placeholder="
                              $t(
                                'fx_spot_forward_swap.trade_form.cpty_stop_loss_limit'
                              )
                            "
                            :disabled="true"
                          />
                          <small class="text-danger">{{ errors[0] }}</small>
                        </validation-provider>
                      </b-form-group>
                    </b-col>
                  </b-row>
                  <!-- client profit limit & client stop loss stop & client stop loss limit -->
                  <b-row>
                    <!-- Take Profit Limit -->
                    <b-col offset-xl="2" cols="6" xl="2" lg="2" md="4" sm="6">
                      <b-form-group
                        :label="$t('equity_bond.trade_form.take_profit_limit')"
                        v-if="tradeForm.enableConnectedOCOOrders"
                      >
                        <validation-provider
                          #default="{ errors }"
                          :name="$t('equity_bond.trade_form.take_profit_limit')"
                        >
                          <cleave
                            id="takeProfitLimit"
                            :state="errors.length > 0 ? false : null"
                            v-model="tradeForm.takeProfitLimit"
                            class="form-control "
                            :raw="true"
                            :options="cleave8DecimalOptions.number"
                            :placeholder="
                              $t('equity_bond.trade_form.take_profit_limit')
                            "
                            :disabled="true"
                          />

                          <small class="text-danger ">{{ errors[0] }}</small>
                        </validation-provider>
                      </b-form-group>
                    </b-col>
                    <!-- Stop Loss Stop -->
                    <b-col cols="6" xl="2" lg="2" md="4" sm="6">
                      <b-form-group
                        :label="$t('equity_bond.trade_form.stop_loss_stop')"
                        v-if="tradeForm.enableConnectedOCOOrders"
                      >
                        <validation-provider
                          #default="{ errors }"
                          :name="$t('equity_bond.trade_form.stop_loss_stop')"
                        >
                          <cleave
                            id="stopLossStop"
                            :state="errors.length > 0 ? false : null"
                            v-model="tradeForm.stopLossStop"
                            class="form-control "
                            :raw="true"
                            :options="cleave8DecimalOptions.number"
                            :placeholder="
                              $t('equity_bond.trade_form.stop_loss_stop')
                            "
                            :disabled="true"
                          />
                          <small class="text-danger ">{{ errors[0] }}</small>
                        </validation-provider>
                      </b-form-group>
                    </b-col>
                    <!-- Stop Loss Limit -->
                    <b-col cols="6" xl="2" lg="2" md="4" sm="6">
                      <b-form-group
                        :label="$t('equity_bond.trade_form.stop_loss_limit')"
                        v-if="tradeForm.enableConnectedOCOOrders"
                      >
                        <validation-provider
                          #default="{ errors }"
                          :name="$t('equity_bond.trade_form.stop_loss_limit')"
                        >
                          <cleave
                            id="stopLossLimit"
                            :state="errors.length > 0 ? false : null"
                            v-model="tradeForm.stopLossLimit"
                            class="form-control "
                            :raw="true"
                            :options="cleave8DecimalOptions.number"
                            :placeholder="
                              $t('equity_bond.trade_form.stop_loss_limit')
                            "
                            :disabled="true"
                          />
                          <small class="text-danger ">{{ errors[0] }}</small>
                        </validation-provider>
                      </b-form-group>
                    </b-col>
                  </b-row>
                </b-card>
              </b-row>

              <!-- /******************************* Trade Date & Time && Security Details***************************************************************** */ -->

              <b-row>
                <!-- /******************************* Security Details***************************************************************** */ -->
                <b-col
                  cols="12"
                  sm="12"
                  md="12"
                  lg="6"
                  xl="6"
                  class="pl-0"
                  style="padding-right:0.5rem!important"
                >
                  <!-- /******************************* Security Details***************************************************************** */ -->

                  <b-card :title="$t('equity_bond.titles.security_details')">
                    <b-row>
                      <!-- cost center -->
                      <b-col cols="6" xl="4" lg="4" md="4" sm="6">
                        <b-form-group
                          :label="$t('equity_bond.trade_form.cost_center')"
                        >
                          <validation-provider
                            #default="{ errors }"
                            :name="$t('equity_bond.trade_form.cost_center')"
                            rules="required"
                          >
                            <v-select
                              :disabled="true"
                              v-model="tradeForm.costCenter"
                              :options="optCostCenters"
                              :clearable="false"
                              :placeholder="
                                $t('equity_bond.trade_form.select_cost_center')
                              "
                              :reduce="(val) => val.CostCenter"
                              label="CostCenter"
                              ref="costCenter"
                            />
                            <small
                              class="text-danger compact_form-validation-message"
                              >{{ errors[0] }}</small
                            >
                          </validation-provider>
                        </b-form-group>
                      </b-col>

                      <!-- security desc-->
                      <b-col cols="6" xl="4" lg="4" md="4" sm="6">
                        <b-form-group
                          :label="$t('equity_bond.trade_form.security_desc')"
                        >
                          <validation-provider
                            #default="{ errors }"
                            :name="$t('equity_bond.trade_form.security_desc')"
                            rules="required"
                          >
                            <v-select
                              v-model="tradeForm.securityDesc"
                              :options="optSecurityDescriptions"
                              :clearable="false"
                              :placeholder="
                                $t(
                                  'equity_bond.trade_form.select_security_desc'
                                )
                              "
                              :reduce="(val) => val"
                              :state="errors.length > 0 ? false : null"
                              :disabled="true"
                              ref="securityDescription"
                            />
                            <small
                              class="text-danger compact_form-validation-message"
                              >{{ errors[0] }}</small
                            >
                          </validation-provider>
                        </b-form-group>
                      </b-col>

                      <!-- ISIN -->
                      <b-col cols="6" xl="4" lg="4" md="4" sm="6">
                        <b-form-group
                          :label="$t('equity_bond.trade_form.isin')"
                        >
                          <validation-provider
                            #default="{ errors }"
                            name="ISIN"
                          >
                            <b-form-input
                              :disabled="true"
                              id="isin"
                              v-model="tradeForm.isin"
                              :name="$t('equity_bond.trade_form.isin')"
                              :state="errors.length > 0 ? false : null"
                              :placeholder="$t('equity_bond.trade_form.isin')"
                              autofocus
                              ref="isin"
                            />
                            <small
                              class="text-danger compact_form-validation-message"
                              >{{ errors[0] }}</small
                            >
                          </validation-provider>
                        </b-form-group>
                      </b-col>

                      <!-- quantity -->
                      <b-col cols="6" xl="4" lg="4" md="4" sm="6">
                        <b-form-group
                          :label="$t('equity_bond.trade_form.quantity')"
                        >
                          <validation-provider
                            #default="{ errors }"
                            :name="$t('equity_bond.trade_form.quantity')"
                            rules="required|min_value:0"
                          >
                            <cleave
                              id="quantity"
                              :state="errors.length > 0 ? false : null"
                              v-model="tradeForm.quantity"
                              class="form-control "
                              :raw="true"
                              :options="cleaveQuantityOptions.number"
                              :placeholder="
                                $t('equity_bond.trade_form.quantity')
                              "
                              @blur.native="calculateAmount"
                              :disabled="true"
                              ref="quantity"
                            />
                            <small
                              class="text-danger compact_form-validation-message"
                              >{{ errors[0] }}</small
                            >
                          </validation-provider>
                        </b-form-group>
                      </b-col>
                      <!-- client price -->
                      <b-col cols="6" xl="4" lg="4" md="4" sm="6">
                        <b-form-group
                          :label="$t('equity_bond.trade_form.client_price')"
                        >
                          <validation-provider
                            #default="{ errors }"
                            :name="$t('equity_bond.trade_form.client_price')"
                            rules="required|min_value:0"
                          >
                            <cleave
                              id="clientPrice"
                              :state="errors.length > 0 ? false : null"
                              v-model="tradeForm.clientPrice"
                              class="form-control "
                              :raw="true"
                              :options="cleave8DecimalOptions.number"
                              :placeholder="
                                $t('equity_bond.trade_form.client_price')
                              "
                              :disabled="true"
                              ref="clientPrice"
                            />
                            <small
                              class="text-danger compact_form-validation-message"
                              >{{ errors[0] }}</small
                            >
                          </validation-provider>
                        </b-form-group>
                      </b-col>
                      <!-- client price type ccy -->
                      <b-col cols="6" xl="3" lg="3" md="4" sm="6">
                        <b-form-group
                          :label="$t('equity_bond.trade_form.price_type')"
                        >
                          <validation-provider
                            #default="{ errors }"
                            :name="$t('equity_bond.trade_form.price_type')"
                            rules="required"
                          >
                            <v-select
                              v-model="tradeForm.clientPriceType"
                              :options="optClientPriceTypes"
                              :clearable="false"
                              :placeholder="
                                $t('equity_bond.trade_form.price_type')
                              "
                              :reduce="(val) => val"
                              :state="errors.length > 0 ? false : null"
                              :disabled="true"
                              ref="clientPriceType"
                            />
                            <small
                              class="text-danger compact_form-validation-message"
                              >{{ errors[0] }}</small
                            >
                          </validation-provider>
                        </b-form-group>
                      </b-col>
                      <!-- amount -->
                      <b-col cols="6" xl="4" lg="4" md="4" sm="6">
                        <b-form-group
                          :label="$t('equity_bond.trade_form.amount')"
                        >
                          <validation-provider
                            #default="{ errors }"
                            :name="$t('equity_bond.trade_form.amount')"
                          >
                            <cleave
                              id="amount"
                              :state="errors.length > 0 ? false : null"
                              v-model="tradeForm.amount"
                              class="form-control "
                              :raw="true"
                              :options="cleave8DecimalOptions.number"
                              :placeholder="$t('equity_bond.trade_form.amount')"
                              :disabled="true"
                              ref="amount"
                            />
                            <small
                              class="text-danger compact_form-validation-message"
                              >{{ errors[0] }}</small
                            >
                          </validation-provider>
                        </b-form-group>
                      </b-col>

                      <!-- exchange traded -->
                      <b-col cols="6" xl="4" lg="4" md="4" sm="6">
                        <b-form-group
                          :label="$t('equity_bond.trade_form.exchange_traded')"
                        >
                          <validation-provider
                            #default="{ errors }"
                            :name="$t('equity_bond.trade_form.exchange_traded')"
                          >
                            <v-select
                              v-model="tradeForm.exchangeTraded"
                              :options="optExchangeTrades"
                              :clearable="false"
                              :placeholder="
                                $t(
                                  'equity_bond.trade_form.select_exchange_traded'
                                )
                              "
                              :reduce="(val) => val"
                              :disabled="true"
                              ref="exchangeTraded"
                            />
                            <small
                              class="text-danger compact_form-validation-message"
                              >{{ errors[0] }}</small
                            >
                          </validation-provider>
                        </b-form-group>
                      </b-col>

                      <!-- client -->
                      <b-col cols="6" xl="4" lg="4" md="4" sm="6">
                        <b-form-group
                          :label="$t('equity_bond.trade_form.client')"
                        >
                          <validation-provider
                            #default="{ errors }"
                            :name="$t('equity_bond.trade_form.client')"
                            rules="required"
                          >
                            <v-select
                              v-model="tradeForm.client"
                              :options="optClients"
                              :clearable="false"
                              :placeholder="
                                $t('equity_bond.trade_form.select_client')
                              "
                              :reduce="(val) => val"
                              :state="errors.length > 0 ? false : null"
                              :disabled="true"
                              ref="client"
                            />
                            <small
                              class="text-danger compact_form-validation-message"
                              >{{ errors[0] }}</small
                            >
                          </validation-provider>
                        </b-form-group>
                      </b-col>
                      <!-- client account -->
                      <b-col cols="6" xl="4" lg="4" md="4" sm="6">
                        <b-form-group
                          :label="$t('equity_bond.trade_form.client_account')"
                        >
                          <validation-provider
                            #default="{ errors }"
                            :name="$t('equity_bond.trade_form.client_account')"
                            rules="required"
                          >
                            <b-form-input
                              :disabled="true"
                              id="clientAccount"
                              v-model="tradeForm.clientAccount"
                              name="clientAccount"
                              :state="errors.length > 0 ? false : null"
                              :placeholder="
                                $t('equity_bond.trade_form.client_account')
                              "
                              ref="Account"
                            />
                            <small
                              class="text-danger compact_form-validation-message"
                              >{{ errors[0] }}</small
                            >
                          </validation-provider>
                        </b-form-group>
                      </b-col>

                      <!-- counter party -->
                      <b-col cols="6" xl="4" lg="4" md="4" sm="6">
                        <b-form-group
                          :label="$t('equity_bond.trade_form.counterparty')"
                        >
                          <validation-provider
                            #default="{ errors }"
                            :name="$t('equity_bond.trade_form.counterparty')"
                            rules="required"
                          >
                            <v-select
                              v-model="tradeForm.counterparty"
                              :options="optCounterParties"
                              :clearable="false"
                              :placeholder="
                                $t('equity_bond.trade_form.select_counterparty')
                              "
                              :reduce="(val) => val"
                              :disabled="true"
                              ref="counterparty"
                            />
                            <small
                              class="text-danger compact_form-validation-message"
                              >{{ errors[0] }}</small
                            >
                          </validation-provider>
                        </b-form-group>
                      </b-col>
                      <!-- cpty account -->
                      <b-col cols="6" xl="4" lg="4" md="4" sm="6">
                        <b-form-group
                          :label="$t('equity_bond.trade_form.cpty_account')"
                        >
                          <validation-provider
                            #default="{ errors }"
                            :name="$t('equity_bond.trade_form.cpty_account')"
                            rules="required"
                          >
                            <b-form-input
                              :disabled="true"
                              id="isin"
                              v-model="tradeForm.cptyAccount"
                              name="cpty account"
                              :state="errors.length > 0 ? false : null"
                              :placeholder="
                                $t('equity_bond.trade_form.cpty_account')
                              "
                              ref="counterpartyAccount"
                            />
                            <small
                              class="text-danger compact_form-validation-message"
                              >{{ errors[0] }}</small
                            >
                          </validation-provider>
                        </b-form-group>
                      </b-col>
                      <!-- counter party trader -->
                      <b-col cols="6" xl="4" lg="4" md="4" sm="6">
                        <b-form-group
                          :label="
                            $t('equity_bond.trade_form.counterparty_trader')
                          "
                        >
                          <validation-provider
                            #default="{ errors }"
                            :name="
                              $t('equity_bond.trade_form.counterparty_trader')
                            "
                          >
                            <v-select
                              v-model="tradeForm.counterpartyTrader"
                              :options="optCounterPartyTraders"
                              :clearable="false"
                              :placeholder="
                                $t(
                                  'equity_bond.trade_form.select_counterparty_trader'
                                )
                              "
                              :reduce="(val) => val"
                              :disabled="true"
                              ref="counterpartyTrader"
                            />
                            <small
                              class="text-danger compact_form-validation-message"
                              >{{ errors[0] }}</small
                            >
                          </validation-provider>
                        </b-form-group>
                      </b-col>
                      <!-- sfc user -->
                      <b-col cols="6" xl="4" lg="4" md="4" sm="6">
                        <b-form-group
                          :label="$t('equity_bond.trade_form.sfc_user')"
                        >
                          <validation-provider
                            #default="{ errors }"
                            :name="$t('equity_bond.trade_form.sfc_user')"
                            rules="required"
                          >
                            <v-select
                              v-model="tradeForm.scfUser"
                              :options="optScfUsers"
                              :clearable="false"
                              :placeholder="
                                $t('equity_bond.trade_form.select_sfc_user')
                              "
                              :reduce="(val) => val"
                              :state="errors.length > 0 ? false : null"
                              :disabled="true"
                              ref="tcUser"
                            />
                            <small
                              class="text-danger compact_form-validation-message"
                              >{{ errors[0] }}</small
                            >
                          </validation-provider>
                        </b-form-group>
                      </b-col>
                      <!-- acting capacity -->
                      <b-col cols="6" xl="4" lg="4" md="4" sm="6">
                        <b-form-group
                          :label="$t('equity_bond.trade_form.acting_capacity')"
                        >
                          <validation-provider
                            #default="{ errors }"
                            :name="$t('equity_bond.trade_form.acting_capacity')"
                            rules="required"
                          >
                            <v-select
                              v-model="tradeForm.actingCapaticy"
                              :options="optActingCapacities"
                              :clearable="false"
                              :placeholder="
                                $t(
                                  'equity_bond.trade_form.select_acting_capacity'
                                )
                              "
                              :reduce="(val) => val"
                              :state="errors.length > 0 ? false : null"
                              :disabled="true"
                              ref="actingCapaticy"
                            />
                            <small
                              class="text-danger compact_form-validation-message"
                              >{{ errors[0] }}</small
                            >
                          </validation-provider>
                        </b-form-group>
                      </b-col>
                    </b-row>
                  </b-card>

                  <!-- /******************************* Trade Date & Time***************************************************************** */ -->

                  <b-card :title="$t('equity_bond.titles.trade_date_time')">
                    <b-row>
                      <!-- Trade Date -->
                      <b-col cols="6" xl="4" lg="4" md="4" sm="6">
                        <b-form-group
                          :label="$t('equity_bond.trade_form.trade_date')"
                        >
                          <validation-provider
                            #default="{ errors }"
                            :name="$t('equity_bond.trade_form.trade_date')"
                          >
                            <b-input-group
                              class="compact_form_input-group"
                              :class="
                                tradeForm.tradeDateName == 'Sunday' ||
                                tradeForm.tradeDateName == 'Saturday'
                                  ? 'tradeDateName'
                                  : ''
                              "
                              :append="tradeForm.tradeDateName"
                            >
                              <flat-pickr
                                :placeholder="
                                  $t('equity_bond.trade_form.trade_date')
                                "
                                v-model="tradeForm.tradeDate"
                                onClose="testClose"
                                class="form-control "
                                :config="dateConfig"
                                :disabled="true"
                                ref="tradeDate"
                              />
                            </b-input-group>
                            <small
                              class="text-danger compact_form-validation-message"
                              >{{ errors[0] }}</small
                            >
                          </validation-provider>
                        </b-form-group>
                      </b-col>
                      <!-- Settlement Date -->
                      <b-col cols="6" xl="4" lg="4" md="4" sm="6">
                        <b-form-group
                          :label="$t('equity_bond.trade_form.settlement_date')"
                        >
                          <validation-provider
                            #default="{ errors }"
                            :name="$t('equity_bond.trade_form.settlement_date')"
                          >
                            <b-input-group
                              class="compact_form_input-group"
                              :class="
                                tradeForm.settlementDateName == 'Sunday' ||
                                tradeForm.settlementDateName == 'Saturday'
                                  ? 'settlementDateName'
                                  : ''
                              "
                              :append="tradeForm.settlementDateName"
                            >
                              <flat-pickr
                                :placeholder="
                                  $t('equity_bond.trade_form.settlement_date')
                                "
                                v-model="tradeForm.settlementDate"
                                onClose="testClose"
                                class="form-control "
                                :config="dateConfig"
                                :disabled="true"
                                ref="settlementDate"
                              />
                            </b-input-group>
                            <small
                              class="text-danger compact_form-validation-message"
                              >{{ errors[0] }}</small
                            >
                          </validation-provider>
                        </b-form-group>
                      </b-col>
                      <!-- execution time -->
                      <b-col cols="6" xl="4" lg="4" md="4" sm="6">
                        <b-form-group
                          :label="$t('equity_bond.trade_form.execution_time')"
                        >
                          <validation-provider
                            #default="{ errors }"
                            :name="$t('equity_bond.trade_form.execution_time')"
                          >
                            <cleave
                              id="executionTime"
                              v-model="tradeForm.executionTime"
                              class="form-control "
                              :raw="false"
                              :options="cleaveOptions.time"
                              :placeholder="
                                $t('equity_bond.trade_form.execution_time')
                              "
                              :state="errors.length > 0 ? false : null"
                              :disabled="true"
                              ref="executionTime"
                            />
                            <small
                              class="text-danger compact_form-validation-message"
                              >{{ errors[0] }}</small
                            >
                          </validation-provider>
                        </b-form-group>
                      </b-col>
                    </b-row>
                  </b-card>
                </b-col>

                <!-- /******************************* Trade Details && Settlement Details ***************************************************************** */ -->
                <b-col
                  cols="12"
                  sm="12"
                  md="12"
                  lg="6"
                  xl="6"
                  class="pr-0"
                  style="padding-left:0.5rem!important"
                >
                  <!-- /******************************* Trade Details***************************************************************** */ -->

                  <b-card :title="$t('equity_bond.titles.trade_details')">
                    <b-row>
                      <!-- Client Rate -->
                      <b-col cols="6" xl="4" lg="4" md="6" sm="6">
                        <b-form-group
                          :label="$t('equity_bond.trade_form.client_rate')"
                        >
                          <validation-provider
                            #default="{ errors }"
                            :name="$t('equity_bond.trade_form.client_rate')"
                            :rules="
                              tradeForm.calculateCommissionFromRate1
                                ? 'min_value:0'
                                : ''
                            "
                          >
                            <b-input-group
                              append="Bps"
                              class="compact_form_input-group"
                            >
                              <b-input-group-prepend is-text>
                                <b-form-checkbox
                                  plain
                                  name="checkbox-input"
                                  v-model="
                                    tradeForm.calculateCommissionFromRate1
                                  "
                                  v-b-tooltip.hover.top
                                  :title="
                                    $t(
                                      'equity_bond.trade_form.client_rate_tooltip'
                                    )
                                  "
                                  :disabled="true"
                                >
                                </b-form-checkbox>
                              </b-input-group-prepend>

                              <cleave
                                id="clientRate"
                                :state="errors.length > 0 ? false : null"
                                v-model="tradeForm.clientRate"
                                class="form-control "
                                :raw="true"
                                :options="cleave8DecimalOptions.number"
                                :placeholder="
                                  $t('equity_bond.trade_form.client_rate')
                                "
                                :disabled="true"
                                ref="commissionRate"
                              />
                            </b-input-group>

                            <small
                              class="text-danger compact_form-validation-message"
                              >{{ errors[0] }}</small
                            >
                          </validation-provider>
                        </b-form-group>
                      </b-col>
                      <!-- Client Commission -->
                      <b-col cols="6" xl="4" lg="4" md="6" sm="6">
                        <b-form-group
                          :label="$t('equity_bond.trade_form.client_comission')"
                        >
                          <validation-provider
                            #default="{ errors }"
                            :name="
                              $t('equity_bond.trade_form.client_comission')
                            "
                            :rules="
                              !tradeForm.calculateCommissionFromRate1
                                ? 'min_value:0'
                                : ''
                            "
                          >
                            <cleave
                              id="clientCommission"
                              :state="errors.length > 0 ? false : null"
                              v-model="tradeForm.clientCommission"
                              class="form-control "
                              :raw="true"
                              :options="cleaveOptions.number"
                              :placeholder="
                                $t('equity_bond.trade_form.client_comission')
                              "
                              :disabled="true"
                              ref="commissionAmount"
                            />
                            <small
                              class="text-danger compact_form-validation-message"
                              >{{ errors[0] }}</small
                            >
                          </validation-provider>
                        </b-form-group>
                      </b-col>
                      <!-- Client amount -->
                      <b-col cols="6" xl="4" lg="4" md="6" sm="6">
                        <b-form-group
                          :label="$t('equity_bond.trade_form.client_amount')"
                        >
                          <validation-provider
                            #default="{ errors }"
                            :name="$t('equity_bond.trade_form.client_amount')"
                            rules="min_value:0"
                          >
                            <cleave
                              id="clientAmmount"
                              :state="errors.length > 0 ? false : null"
                              v-model="tradeForm.clientAmount"
                              class="form-control "
                              :raw="true"
                              :options="cleaveOptions.number"
                              :placeholder="
                                $t('equity_bond.trade_form.client_amount')
                              "
                              :disabled="true"
                              ref="clientAmount"
                            />
                            <small
                              class="text-danger compact_form-validation-message"
                              >{{ errors[0] }}</small
                            >
                          </validation-provider>
                        </b-form-group>
                      </b-col>
                      <!-- Counterparty Rate -->
                      <b-col cols="6" xl="4" lg="4" md="6" sm="6">
                        <b-form-group
                          :label="
                            $t('equity_bond.trade_form.counterparty_rate')
                          "
                        >
                          <validation-provider
                            #default="{ errors }"
                            :name="
                              $t('equity_bond.trade_form.counterparty_rate')
                            "
                            :rules="
                              tradeForm.calculateCommissionFromRate2
                                ? 'min_value:0'
                                : ''
                            "
                          >
                            <b-input-group
                              append="Bps"
                              class="compact_form_input-group"
                            >
                              <b-input-group-prepend is-text>
                                <b-form-checkbox
                                  plain
                                  name="checkbox-input"
                                  v-model="
                                    tradeForm.calculateCommissionFromRate2
                                  "
                                  v-b-tooltip.hover.top
                                  :title="
                                    $t(
                                      'equity_bond.trade_form.counterparty_rate_tooltip'
                                    )
                                  "
                                  :disabled="true"
                                />
                              </b-input-group-prepend>

                              <cleave
                                id="counterpartyRate"
                                :state="errors.length > 0 ? false : null"
                                v-model="tradeForm.counterpartyRate"
                                class="form-control "
                                :raw="true"
                                :options="cleave8DecimalOptions.number"
                                :placeholder="
                                  $t('equity_bond.trade_form.counterparty_rate')
                                "
                                :disabled="true"
                                ref="counterpartyRate"
                              />
                            </b-input-group>

                            <small
                              class="text-danger compact_form-validation-message"
                              >{{ errors[0] }}</small
                            >
                          </validation-provider>
                        </b-form-group>
                      </b-col>

                      <!-- cpty comission -->
                      <b-col cols="6" xl="4" lg="4" md="6" sm="6">
                        <b-form-group
                          :label="$t('equity_bond.trade_form.cpty_comission')"
                        >
                          <validation-provider
                            #default="{ errors }"
                            :name="$t('equity_bond.trade_form.cpty_comission')"
                            :rules="
                              !tradeForm.calculateCommissionFromRate2
                                ? 'min_value:0'
                                : ''
                            "
                          >
                            <cleave
                              id="cptyComission"
                              :state="errors.length > 0 ? false : null"
                              v-model="tradeForm.cptyCommission"
                              class="form-control "
                              :raw="true"
                              :options="cleaveOptions.number"
                              :placeholder="
                                $t('equity_bond.trade_form.cpty_comission')
                              "
                              :disabled="true"
                              ref="counterpartyCommission"
                            />
                            <small
                              class="text-danger compact_form-validation-message"
                              >{{ errors[0] }}</small
                            >
                          </validation-provider>
                        </b-form-group>
                      </b-col>
                      <!-- counterparty amount -->
                      <b-col cols="6" xl="4" lg="4" md="6" sm="6">
                        <b-form-group
                          :label="
                            $t('equity_bond.trade_form.counterparty_amount')
                          "
                        >
                          <validation-provider
                            #default="{ errors }"
                            :name="
                              $t('equity_bond.trade_form.counterparty_amount')
                            "
                            rules="min_value:0"
                          >
                            <cleave
                              id="counterpartyAmount"
                              :state="errors.length > 0 ? false : null"
                              v-model="tradeForm.counterpartyAmount"
                              class="form-control "
                              :raw="true"
                              :options="cleaveOptions.number"
                              :placeholder="
                                $t('equity_bond.trade_form.counterparty_amount')
                              "
                              :disabled="true"
                              ref="counterpartyAmount"
                            />
                            <small
                              class="text-danger compact_form-validation-message"
                              >{{ errors[0] }}</small
                            >
                          </validation-provider>
                        </b-form-group>
                      </b-col>
                      <!-- profit -->
                      <b-col cols="6" xl="3" lg="3" md="6" sm="6">
                        <b-form-group
                          :label="$t('equity_bond.trade_form.profit')"
                        >
                          <validation-provider
                            #default="{ errors }"
                            :name="$t('equity_bond.trade_form.profit')"
                          >
                            <cleave
                              id="profit"
                              :state="errors.length > 0 ? false : null"
                              v-model="tradeForm.profit"
                              class="form-control "
                              :raw="true"
                              :options="cleaveOptions.number"
                              :placeholder="$t('equity_bond.trade_form.profit')"
                              :disabled="true"
                              ref="tcProfit"
                            />
                            <small
                              class="text-danger compact_form-validation-message"
                              >{{ errors[0] }}</small
                            >
                          </validation-provider>
                        </b-form-group>
                      </b-col>

                      <!-- order taken via -->
                      <b-col cols="6" xl="3" lg="3" md="6" sm="6">
                        <b-form-group
                          :label="$t('equity_bond.trade_form.order_taken_via')"
                        >
                          <validation-provider
                            #default="{ errors }"
                            :name="$t('equity_bond.trade_form.order_taken_via')"
                          >
                            <v-select
                              v-model="tradeForm.orderTakenVia"
                              :options="optOrderTakenVias"
                              :clearable="false"
                              :placeholder="
                                $t(
                                  'equity_bond.trade_form.select_order_taken_via'
                                )
                              "
                              :reduce="(val) => val"
                              :disabled="true"
                              ref="orderTakenVia"
                            />
                            <small
                              class="text-danger compact_form-validation-message"
                              >{{ errors[0] }}</small
                            >
                          </validation-provider>
                        </b-form-group>
                      </b-col>
                      <!-- order taken through -->
                      <b-col cols="6" xl="3" lg="3" md="6" sm="6">
                        <b-form-group
                          :label="
                            $t('equity_bond.trade_form.order_given_through')
                          "
                        >
                          <validation-provider
                            #default="{ errors }"
                            :name="
                              $t('equity_bond.trade_form.order_given_through')
                            "
                          >
                            <v-select
                              v-model="tradeForm.orderGivenThrough"
                              :options="optOrderGivenThroughs"
                              :clearable="false"
                              :placeholder="
                                $t(
                                  'equity_bond.trade_form.select_order_given_through'
                                )
                              "
                              :reduce="(val) => val"
                              :disabled="true"
                              ref="orderGivenThrough"
                            />
                            <small
                              class="text-danger compact_form-validation-message"
                              >{{ errors[0] }}</small
                            >
                          </validation-provider>
                        </b-form-group>
                      </b-col>
                      <!-- tax -->
                      <!-- <b-col cols="6" xl="3" lg="3" md="6" sm="6">
                        <b-form-group :label="$t('equity_bond.trade_form.tax')">
                          <validation-provider
                            #default="{ errors }"
                            :name="$t('equity_bond.trade_form.tax')"
                            rules="min_value:0"
                          >
                            <b-form-input
                              id="tax"
                              v-model.number="tradeForm.tax"
                              name="tax"
                              type="number"
                              :state="errors.length > 0 ? false : null"
                              :placeholder="$t('equity_bond.trade_form.tax')"
                              autofocus
                              :disabled="true"
                              ref="tax"
                            />
                            <small
                              class="text-danger compact_form-validation-message"
                              >{{ errors[0] }}</small
                            >
                          </validation-provider>
                        </b-form-group>
                      </b-col> -->

                      <!-- note -->
                      <b-col cols="12" xl="12" lg="12" md="12" sm="12">
                        <b-form-group>
                          <validation-provider
                            #default="{ errors }"
                            :name="$t('equity_bond.trade_form.note')"
                          >
                            <b-form-textarea
                              id="form-textarea"
                              :placeholder="$t('equity_bond.trade_form.note')"
                              no-resize
                              v-model="tradeForm.note"
                              :disabled="true"
                              ref="traderNote"
                              class="compact_form-textarea"
                            />
                            <small
                              class="text-danger compact_form-validation-message"
                              >{{ errors[0] }}</small
                            >
                          </validation-provider>
                        </b-form-group>
                      </b-col>
                    </b-row>
                  </b-card>

                  <!-- /******************************* Settlement Details***************************************************************** */ -->
                  <b-card :title="$t('equity_bond.titles.settlement_details')">
                    <b-row>
                      <!-- UTI  -->
                      <b-col cols="6" xl="4" lg="4" md="4" sm="6">
                        <b-form-group :label="$t('equity_bond.trade_form.uti')">
                          <validation-provider
                            #default="{ errors }"
                            :name="$t('equity_bond.trade_form.uti')"
                          >
                            <b-form-input
                              id="UTI"
                              v-model="tradeForm.uti"
                              name="UTI"
                              :state="errors.length > 0 ? false : null"
                              :placeholder="$t('equity_bond.trade_form.uti')"
                              :disabled="true"
                            />

                            <small
                              class="text-danger compact_form-validation-message"
                              >{{ errors[0] }}</small
                            >
                          </validation-provider>
                        </b-form-group>
                      </b-col>
                      <!-- Unique Link ID -->
                      <b-col cols="6" xl="4" lg="4" md="4" sm="6">
                        <b-form-group
                          :label="$t('equity_bond.trade_form.unique_link_id')"
                        >
                          <validation-provider
                            #default="{ errors }"
                            :name="$t('equity_bond.trade_form.unique_link_id')"
                          >
                            <b-form-input
                              id="uniqueLinkID"
                              v-model.number="tradeForm.uniqueLinkID"
                              type="number"
                              name="uniqueLinkID"
                              :state="errors.length > 0 ? false : null"
                              :placeholder="
                                $t('equity_bond.trade_form.unique_link_id')
                              "
                              :disabled="true"
                              ref="uniqueLinkId"
                            />
                            <small
                              class="text-danger compact_form-validation-message"
                              >{{ errors[0] }}</small
                            >
                          </validation-provider>
                        </b-form-group>
                      </b-col>
                      <!-- Trade Ref -->
                      <b-col cols="6" xl="4" lg="4" md="4" sm="6">
                        <b-form-group
                          :label="$t('equity_bond.trade_form.trade_ref')"
                        >
                          <validation-provider
                            #default="{ errors }"
                            :name="$t('equity_bond.trade_form.trade_ref')"
                          >
                            <b-form-input
                              :disabled="true"
                              id="tradeRef"
                              v-model="tradeForm.tradeRef"
                              name="tradeRef"
                              :state="errors.length > 0 ? false : null"
                              :placeholder="
                                $t('equity_bond.trade_form.trade_ref')
                              "
                            />
                            <small
                              class="text-danger compact_form-validation-message"
                              >{{ errors[0] }}</small
                            >
                          </validation-provider>
                        </b-form-group>
                      </b-col>
                    </b-row>
                  </b-card>
                </b-col>
              </b-row>
            </b-form>
          </validation-observer>
        </b-container>
        <template #overlay v-if="tradeModalLoading">
          <div
            class="text-center d-flex justify-content-center align-items-start"
          >
            <b-icon icon="stopwatch" font-scale="3" animation="cylon"> </b-icon>
            <p id="cancel-label">
              <feather-icon icon="DownloadIcon" /><b>
                {{
                  $t("equity_bond.overlay_messages.reverse_entry_message")
                }}</b
              >
            </p>
          </div>
        </template>
      </b-overlay>
      <!-- modal footer -->
      <template #modal-footer>
        <b-row class="d-flex align-items-center" style="flex: 1;">
          <!-- trade actions -->
          <b-col
            cols="6"
            md="6"
            xl="6"
            lg="6"
            sm="6"
            class=" pl-0 d-flex justify-content-between align-items-center"
          >
            <b-button
              id="tradeAction-button"
              v-ripple.400="'rgba(255, 255, 255, 0.15)'"
              variant="outline-primary"
              size="sm"
              class="float-left mb-0"
              :disabled="actionsData == null || actionsData.length == 0"
            >
              <feather-icon
                icon="EyeIcon"
                size="14"
                style="margin-right: 0.2rem;"
              />

              <span class="text-nowrap">{{
                $t("equity_bond.buttons.trade_actions")
              }}</span>
            </b-button>
            <b-popover
              target="tradeAction-button"
              placement="top"
              triggers="click"
              id="actions-popover"
            >
              <template #title>
                {{ $t("equity_bond.titles.trade_actions") }}
              </template>
              <b-table-simple hover small caption-top responsive bordered>
                <b-tbody class="compact_form_actions-table">
                  <b-tr v-for="(action, index) in actionsData" :key="index">
                    <td style="width: 20%;">
                      {{ action.type }}
                    </td>
                    <td style="width: 20%;">
                      {{ action.user }}
                    </td>
                    <td style="width: 30%;">
                      {{ action.date }}
                    </td>
                    <td style="width: 30%;">
                      {{ action.reason }}
                    </td>
                  </b-tr>
                </b-tbody>
              </b-table-simple>
            </b-popover>
          </b-col>
          <!-- trade ok operation buttons -->
          <b-col
            cols="6"
            md="6"
            xl="6"
            lg="6"
            sm="6"
            class=" pr-0 d-flex justify-content-end align-items-center"
          >
            <b-button
              v-ripple.400="'rgba(255, 255, 255, 0.15)'"
              variant="secondary"
              size="sm"
              class="float-right mr-1"
              @click="resetTradeModal"
            >
              {{ $t("equity_bond.modals.modal_close") }}
            </b-button>
            <b-button
              v-ripple.400="'rgba(255, 255, 255, 0.15)'"
              variant="primary"
              size="sm"
              class="float-right mr-1"
              @click="tradeOkOperation"
            >
              {{ $t("equity_bond.modals.create_reversal_modal_ok") }}
            </b-button>
          </b-col>
        </b-row>
      </template>
      {{/****END*** reverse for trades modal*/}}
    </b-modal>
  </div>
</template>

<script>
import {
  BOverlay,
  BCard,
  BRow,
  BCol,
  BFormInput,
  BButton,
  BTable,
  BMedia,
  BAvatar,
  BLink,
  BBadge,
  BDropdown,
  BDropdownItem,
  BPagination,
  BFormRating,
  VBModal,
  BTabs,
  BTab,
  BCardText,
  BIcon,
  BPopover,
} from "bootstrap-vue";
import store from "@/store";
import { onUnmounted } from "@vue/composition-api";
import equityStoreModule from "./EquityStoreModule";
import { ValidationProvider, ValidationObserver } from "vee-validate";
import { required, alphaNum, email } from "@validations";
import ToastificationContent from "@core/components/toastification/ToastificationContent.vue";
import Ripple from "vue-ripple-directive";
import vSelect from "vue-select";
import flatPickr from "vue-flatpickr-component";
import "flatpickr/dist/flatpickr.css";
import axios from "axios";
import axiosIns from "@/libs/axios";
import router from "@/router";
import moment from "moment";
import Cleave from "vue-cleave-component";
import "animate.css";
import _ from "lodash";
import { mapState, mapGetters, mapActions } from "vuex";

export default {
  components: {
    BOverlay,
    BCard,
    BRow,
    BCol,
    BFormInput,
    BButton,
    BTable,
    BMedia,
    BAvatar,
    BLink,
    BBadge,
    BTabs,
    BTab,
    BCardText,
    BDropdown,
    BDropdownItem,
    BPagination,
    BFormRating,
    BIcon,
    BPopover,
    vSelect,
    ValidationProvider,
    ValidationObserver,
    ToastificationContent,
    flatPickr,
    Cleave,
  },

  directives: {
    Ripple,
    "b-modal": VBModal,
  },

  setup() {
    const EQUITYBONDS_APP_STORE_MODULE_NAME = "equity&bond";
    // Register module
    if (!store.hasModule(EQUITYBONDS_APP_STORE_MODULE_NAME))
      store.registerModule(
        EQUITYBONDS_APP_STORE_MODULE_NAME,
        equityStoreModule
      );

    // UnRegister on leave
    onUnmounted(() => {
      if (store.hasModule(EQUITYBONDS_APP_STORE_MODULE_NAME))
        store.unregisterModule(EQUITYBONDS_APP_STORE_MODULE_NAME);
    });
  },

  mounted() {},

  props: {
    searchedCounterparties: {
      type: Array,
      default: function() {
        return [];
      },
    },
    searchedClients: {
      type: Array,
      default: function() {
        return [];
      },
    },
    optCostCenters: {
      type: Array,
      default: function() {
        return [];
      },
    },
    optClients: {
      type: Array,
      default: function() {
        return [];
      },
    },
    optCounterParties: {
      type: Array,
      default: function() {
        return [];
      },
    },
  },

  data() {
    return {
      allEquityBondsData: [],
      reverseEntryTradeModal: false,
      tradeModalLoading: false,
      orderDurationDataDisabled: true,
      orderDurationTimeDisabled: true,
      orderDurationInputsVisible: true,
      optEntryTypes: ["Order", "Execution"],
      optbuySells: ["Buy", "Sell", "Sell Short"],
      optOrderTypes: ["Limit", "Market", "Stop Limit", "Stop", "Trailing Stop"],
      optTimeInForces: ["DAY", "GTC", "GTD", "FOK", "FAK"],
      optInstrumentTypes: ["EQ", "FI"],
      optSecurityDescriptions: [],
      optClientPriceTypes: [],
      optExchangeTrades: [],
      optCounterPartyTraders: [],
      optScfUsers: [],
      optActingCapacities: ["PRINCIPAL", "AGENT", "MATCHED PRINCIPAL"],
      optOrderTakenVias: [],
      optOrderGivenThroughs: [],
      fetchedTradeData: {},
      actionsData: null,
      checkVerify: false,

      tradeForm: {
        costCenter: null,

        ID: null,
        orderDurationDate: null,
        orderDurationTime: null,
        entryType: null,
        buySell: null,
        orderType: null,
        timeInForce: null,
        orderDate: null,
        orderTime: null,
        enableConnectedOCOOrders: false,
        stopPrice: null,
        trailingAmount: null,
        takeProfitLimit: null,
        stopLossStop: null,
        stopLossLimit: null,
        cptyTakeProfitLimit: null,
        cptyStopLossStop: null,
        cptyStopLossLimit: null,
        instrumentType: "EQ",
        prop: false,
        securityDesc: null,
        quantity: null,
        clientPrice: null,
        clientPriceType: null,
        amount: null,
        client: null,
        clientAccount: null,
        isin: null,
        exchangeTraded: null,
        counterparty: null,
        cptyAccount: null,
        counterpartyTrader: null,
        scfUser: null,
        actingCapaticy: null,
        tradeDate: null,
        settlementDate: null,
        executionTime: null,
        clientRate: null,
        clientCommission: null,
        clientAmount: null,
        counterpartyRate: null,
        tax: null,
        cptyCommission: null,
        counterpartyAmount: null,
        orderTakenVia: null,
        orderGivenThrough: null,
        profit: null,
        tradeReportingNeeded: null,
        note: null,
        calculateCommissionFromRate1: true,
        calculateCommissionFromRate2: true,
        uti: null,
        uniqueLinkID: null,
        tradeRef: null,
        tradeStart: null,
        tradeEnd: null,
        settleStart: null,
        settleEnd: null,
      },
      timeConfig: {
        enableTime: true,
        noCalendar: true,
        dateFormat: "H:i:s",
        time_24hr: true,
      },
      dateConfig: {
        allowInput: true,
        dateFormat: "d-m-Y",
      },
      cleaveOptions: {
        number: {
          numeral: true,
          numeralThousandsGroupStyle: "thousand",
          numeralDecimalScale: 2,
        },
        time: {
          time: true,
          timePattern: ["h", "m", "s"],
        },
      },
      cleave6DecimalOptions: {
        number: {
          numeral: true,
          numeralThousandsGroupStyle: "thousand",
          numeralDecimalScale: 6,
        },
      },
      cleave8DecimalOptions: {
        number: {
          numeral: true,
          numeralThousandsGroupStyle: "thousand",
          numeralDecimalScale: 8,
          numeralDecimalMarkAlternativeInput: ".",
          completeDecimalsOnBlur: true,
        },
      },
      cleaveQuantityOptions: {
        number: {
          numeral: true,
          numeralThousandsGroupStyle: "thousand",
          numeralDecimalScale: 0,
          numeralIntegerScale: 0,
        },
      },
    };
  },
  computed: {
    user: {
      get() {
        return this.$store.getters["authUser"];
      },
      set(newValue) {
        return this.$store.dispatch("setUser", newValue);
      },
    },
  },
  methods: {
    ...mapActions(["saveLastSearchEquityBond"]),
    getArangeEquityBondTCUsers() {
      store
        .dispatch("equity&bond/fetchArangeEquityBondTCUsers", {
          auth: this.user,
          teamName: this.user.role,
        })
        .then((res) => {
          if (res.data.info == "Pass") {
            this.optScfUsers = res.data.users;
          } else if (res.data.info == "no_users") {
            this.errorMessage(
              this.$t("equity_bond.messages.user_error_no_users")
            );
          } else {
            this.errorMessage(res.data.message);
          }
        })
        .catch((error) => {
          console.log(error);
        });
    },
    getArrangeEquityBondOrderTakenGivenThrough() {
      store
        .dispatch(
          "equity&bond/fetchArrangeEquityBondOrderTakenGivenThrough",
          this.user
        )
        .then((res) => {
          if (
            res.data.orderGivenThroughs.length > 0 ||
            res.data.orderTakenVias.length > 0
          ) {
            this.optOrderGivenThroughs = res.data.orderGivenThroughs;
            this.optOrderTakenVias = res.data.orderTakenVias;
          } else {
            this.errorMessage(
              this.$t("equity_bond.messages.not_found_given_order_data")
            );
          }
        })
        .catch((error) => {
          console.log(error);
        });
    },
    getArrangeEquityBondCurrencies() {
      store
        .dispatch("equity&bond/fetchArrangeEquityBondCurrencies", this.user)
        .then((res) => {
          if (res.data.info == "Pass") {
            this.optClientPriceTypes = res.data.currencies;
          } else {
            this.errorMessage(res.data.message);
          }
        })
        .catch((error) => {
          console.log(error);
        });
    },
    tradeOkOperation(bvModalEvt) {
      bvModalEvt.preventDefault();
      const self = this;
      this.$refs.tradeValidation.validate().then((success) => {
        if (success) {
          this.$checkAccessRight("EquityBond", "Reverse").then((res) => {
            if (res.data.valid) {
              this.$swal({
                title: this.$t("equity_bond.swal.reverse_trade"),
                text: this.$t(
                  "equity_bond.swal.if_reverse_entry_wont_acton_back"
                ),
                icon: "warning",
                showCancelButton: true,
                confirmButtonColor: "#3085d6",
                cancelButtonColor: "#d33",
                confirmButtonText: this.$t("equity_bond.swal.continue_button"),
                cancelButtonText: this.$t("equity_bond.swal.cancel_button"),
                customClass: {
                  confirmButton: "btn btn-success mr-1",
                  cancelButton: "btn btn-danger",
                  denyButton: "btn btn-primary",
                },
                showClass: {
                  popup: "animate__animated animate__fadeInDown",
                },
                hideClass: {
                  popup: "animate__animated animate__fadeOutUp",
                },
              }).then((result) => {
                if (result.isConfirmed) {
                  if (this.tradeForm.uti != null) {
                    this.tradeModalLoading = true;
                    this.checkJournalCreated(
                      "Reverse",
                      this.tradeForm.uti
                    ).then((res) => {
                      if (res.data.info == true) {
                        this.checkEntryReversed(
                          "Reverse",
                          this.tradeForm.uti
                        ).then((res) => {
                          if (res.data.info == true) {
                            this.checkReversalInitiated(
                              "Reverse",
                              this.tradeForm.uti
                            ).then((res) => {
                              if (res.data.info == true) {
                                //set and check trade data
                                let tradeData = self.setTradeData();

                                store
                                  .dispatch("equity&bond/reverseEntry", {
                                    auth: self.user,
                                    trade: tradeData,
                                  })
                                  .then((res) => {
                                    if (res.data.info == "Pass") {
                                      self.tradeModalLoading = false;
                                      self.resetTradeModal();
                                      this.tradeReversedSuccessMessage();
                                      this.$emit("reversedEntry", true);
                                    } else {
                                      self.errorMessage(res.data.message);
                                      self.tradeModalLoading = false;
                                    }
                                  })
                                  .catch((error) => {
                                    console.log(error);
                                  });
                              } else {
                                self.checkReversalInitiatedMessage();
                              }
                            });
                          } else {
                            this.checkEntryReversedMessage(res.data.message);
                          }
                        });
                      } else {
                        this.checkJournalCreatedMessage(res.data.message);
                      }
                    });
                  } else {
                    this.notFoundUTIValue(10000);
                  }
                } else {
                  self.reverseEntryTradeModal = false;
                  self.exitReverseOperationMessage();
                }
              });
            } else {
              this.notAuthToastMessage();
            }
          });
        } else {
          let notEnteredDatas = "";

          let checkRequiredDatas = _.map(
            this.$refs.tradeValidation.errors,
            (value, key) => ({ key, value })
          );

          if (checkRequiredDatas.length > 0) {
            let first = true;
            checkRequiredDatas.forEach((r) => {
              if (r.value.length > 0) {
                if (first) {
                  notEnteredDatas += r.key;
                  first = false;
                } else {
                  notEnteredDatas += "," + r.key;
                  first = false;
                }
              }
            });
            this.checkRequiredInputsMessage(notEnteredDatas, 20000);
          }
        }
      });
    },
    async tradeEnterReasonText() {
      const self = this;
      const { value: reason } = await this.$swal({
        title: this.$t("equity_bond.reason.reverse_reason"),
        text: this.$t("equity_bond.reason.reverse_reason"),
        input: "textarea",
        inputPlaceholder: "Please enter reason for reversal.",
        //icon: "warning",
        showCancelButton: true,
        inputAttributes: {
          style: "margin-top:5px!important",
        },
        confirmButtonColor: "#3085d6",
        cancelButtonColor: "#d33",
        confirmButtonText: self.$t("equity_bond.reason.submit"),
        cancelButtonText: self.$t("equity_bond.reason.Cancel"),
        customClass: {
          confirmButton: "btn btn-primary mr-1",
          cancelButton: "btn btn-danger",
          denyButton: "btn btn-warning",
          text: "mb-1",
        },
        showClass: {
          popup: "animate__animated animate__fadeInDown",
        },
        hideClass: {
          popup: "animate__animated animate__fadeOutUp",
        },
        inputValidator: function(reason) {
          // resons your input
          return new Promise(function(resolve, reject) {
            if (reason != "" && reason != null && reason.length > 3) {
              self.tradeForm.reversalInitiatedReason = reason;
              resolve();
            } else {
              resolve(this.$t("equity_bond.reason.you_need_write_reason"));
              self.validReasonTextMessage();
              //reject("Please enter a valid text");
            }
          });
        },
      });

      if (reason) {
        return new Promise(function(resolve, reject) {
          resolve(true);
        });
      }
    },
    checkEntryReversed(messageType, tcUti) {
      if (tcUti != null && tcUti != "") {
        return new Promise((resolve, reject) => {
          store
            .dispatch("equity&bond/checkTCUtiReserved", {
              auth: this.user,
              type: messageType,
              tcUti: tcUti,
            })
            .then((res) => {
              resolve(res);
            })
            .catch((error) => {
              console.log(error);
              reject(error);
            });
        });
      } else {
        this.errorMessage(this.$t("equity_bond.messages.tc_uti_not_found"));
      }
    },
    checkReversalInitiated(messageType, tcUti) {
      if (tcUti != null && tcUti != "") {
        return new Promise((resolve, reject) => {
          store
            .dispatch("equity&bond/checkReversalInitiated", {
              auth: this.user,
              type: messageType,
              tcUti: tcUti,
            })
            .then((res) => {
              resolve(res);
            })
            .catch((error) => {
              console.log(error);
              reject(error);
            });
        });
      } else {
        this.errorMessage(this.$t("equity_bond.messages.tc_uti_not_found"));
      }
    },
    checkJournalCreated(messageType, tcUti) {
      if (tcUti != null && tcUti != "") {
        return new Promise((resolve, reject) => {
          store
            .dispatch("equity&bond/checkTCUtiJournalCreated", {
              auth: this.user,
              type: messageType,
              tcUti: tcUti,
            })
            .then((res) => {
              resolve(res);
            })
            .catch((error) => {
              console.log(error);
              reject(error);
            });
        });
      } else {
        this.errorMessage(this.$t("equity_bond.messages.tc_uti_not_found"));
      }
    },
    changeTimeInForceType() {
      if (this.tradeForm.timeInForce == "DAY") {
        this.tradeForm.orderDurationDate = this.tradeForm.orderDate;
        this.tradeForm.orderDurationTime = moment()
          .endOf("day")
          .format("HH:mm:ss")
          .toString();
        this.orderDurationDataDisabled = true;
        this.orderDurationTimeDisabled = true;
        this.orderDurationInputsVisible = true;
      } else if (this.tradeForm.timeInForce == "GTC") {
        this.orderDurationInputsVisible = false;
        this.tradeForm.orderDurationDate = null;
        this.tradeForm.orderDurationTime = null;
      } else if (this.tradeForm.timeInForce == "GTD") {
        this.tradeForm.orderDurationDate = this.tradeForm.orderDate;
        this.tradeForm.orderDurationTime = moment()
          .endOf("day")
          .format("HH:mm:ss")
          .toString();
        this.orderDurationDataDisabled = false;
        this.orderDurationTimeDisabled = false;
        this.orderDurationInputsVisible = true;
      } else if (this.tradeForm.timeInForce == "FOK") {
        this.orderDurationInputsVisible = false;
        this.tradeForm.orderDurationDate = null;
        this.tradeForm.orderDurationTime = null;
      } else if (this.tradeForm.timeInForce == "FAK") {
        this.orderDurationInputsVisible = false;
        this.tradeForm.orderDurationDate = null;
        this.tradeForm.orderDurationTime = null;
      }
    },
    resetTradeModal() {
      this.reverseEntryTradeModal = false;
      this.fetchedTradeData = {};
      this.actionsData = null;
      this.checkVerify = false;
    },
    tradeReversedSuccessMessage(val) {
      this.$toast({
        component: ToastificationContent,
        props: {
          title: "Successfully",
          text: this.$t("equity_bond.toast_messages.trade_reversed_success"),
          icon: "ThumbsUpIcon",
          variant: "success",
        },
      });
    },
    checkEntryReversedMessage(message) {
      this.$toast({
        component: ToastificationContent,
        props: {
          title: "Warning",
          text: message,
          icon: "AlertTriangleIcon",
          variant: "warning",
        },
      });
    },
    checkReversalInitiatedMessage(message) {
      this.$toast({
        component: ToastificationContent,
        props: {
          title: "Warning",
          text: message,
          icon: "AlertTriangleIcon",
          variant: "warning",
        },
      });
    },
    databaseErrorMessage(val) {
      this.$toast({
        component: ToastificationContent,
        props: {
          title: "Error",
          text: "Database connection error",
          icon: "AlertTriangleIcon",
          variant: "danger",
        },
      });
    },
    exitReverseOperationMessage() {
      this.$toast({
        component: ToastificationContent,
        props: {
          title: "Warning",
          text: this.$t("equity_bond.messages.trade_reverse_canceled"),
          icon: "AlertTriangleIcon",
          variant: "warning",
        },
      });
    },
    checkTradeVerifyOperationMessage(message) {
      this.$toast({
        component: ToastificationContent,
        props: {
          title: "Warning",
          text: message,
          icon: "AlertTriangleIcon",
          variant: "warning",
        },
      });
    },
    checkJournalOperationsMessage(message, timeout) {
      this.$toast(
        {
          component: ToastificationContent,
          props: {
            title: "Warning",
            text: message,
            icon: "AlertTriangleIcon",
            variant: "warning",
          },
        },
        {
          timeout,
        }
      );
    },
    validReasonTextMessage() {
      this.$toast({
        component: ToastificationContent,
        props: {
          title: "Warning",
          text: this.$t("equity_bond.reason.enter_valid_reason"),
          icon: "AlertTriangleIcon",
          variant: "warning",
        },
      });
    },
    checkRequiredInputsMessage(message, timeout) {
      this.$toast(
        {
          component: ToastificationContent,
          props: {
            title: "Warning",
            text:
              this.$t("equity_bond.messages.required_fill_inputs") +
              ` ${message}`,
            icon: "AlertTriangleIcon",
            variant: "warning",
          },
        },
        {
          timeout,
        }
      );
    },
    notAuthToastMessage(val) {
      this.$toast({
        component: ToastificationContent,
        props: {
          title: "Not Authorized",
          text: this.$t("equity_bond.general_messages.not_authorized_message"),
          icon: "UserXIcon",
          variant: "warning",
        },
      });
    },
    notFoundUTIValue(timeout) {
      this.$toast(
        {
          component: ToastificationContent,
          props: {
            title: "Not Found UTI",
            text: this.$t("equity_bond.general_messages.not_found_tc_uti"),
            icon: "AlertTriangleIcon",
            variant: "warning",
          },
        },
        {
          timeout,
        }
      );
    },
    errorMessage(message) {
      this.$toast({
        component: ToastificationContent,
        props: {
          title: "Error",
          text: message,
          icon: "AlertTriangleIcon",
          variant: "warning",
        },
      });
    },
    checkJournalCreatedMessage(message) {
      this.$toast({
        component: ToastificationContent,
        props: {
          title: "Error",
          text: message,
          icon: "AlertTriangleIcon",
          variant: "danger",
        },
      });
    },
    wrongToastMessage(val) {
      this.$toast({
        component: ToastificationContent,
        props: {
          title: "Error",
          text: val,
          icon: "AlertTriangleIcon",
          variant: "warning",
        },
      });
    },
    onFiltered(filteredItems) {
      this.totalRows = filteredItems.length;
      this.currentPage = 1;
    },
    clearDate() {
      this.newHoliday.date = null;
    },
    DMYdateFormat(val) {
      if (val != undefined) {
        return moment(String(val), "DD-MM-YYYY").format("DD-MM-YYYY");
      }
    },
    YMDdateFormat(val) {
      if (val != undefined) {
        return moment(String(val), "DD-MM-YYYY").format("YYYY-MM-DD");
      }
    },
    dateFormat(val) {
      if (val != undefined) {
        return moment(String(val)).format("DD-MM-YYYY");
      }
    },
    timeFormat(val) {
      if (val != undefined) {
        return moment(String(val), "HH:mm:ss")._i;
      }
    },
    HmstimeFormat(val) {
      if (val != undefined) {
        return moment(String(val)).format("HH:mm:ss");
      }
    },
    dateFormatWithTime(val) {
      if (val != undefined) {
        return moment(String(val), "DD-MM-YYYY HH:mm:ss").format(
          "DD-MM-YYYY HH:mm:ss"
        );
      }
    },
    formatPrice(value, val) {
      if (value != "") {
        let val = (value / 1).toFixed(8).replace(",", ".");
        return val.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ",");
      }
    },
    arrangeInputFormat() {
      if (this.tradeForm.clientPrice && this.tradeForm.clientPrice > 0) {
        this.tradeForm.clientPrice = Number(this.tradeForm.clientPrice).toFixed(
          8
        );
      }

      if (this.tradeForm.counterpartyRate) {
        this.tradeForm.counterpartyRate = Number.parseFloat(
          this.tradeForm.counterpartyRate
        ).toFixed(8);
      }

      if (this.tradeForm.clientRate) {
        this.tradeForm.clientRate = Number.parseFloat(
          this.tradeForm.clientRate
        ).toFixed(8);
      }

      if (this.tradeForm.clientCommission) {
        this.tradeForm.clientCommission = Number(
          this.tradeForm.clientCommission
        ).toFixed(2);
      }

      if (this.tradeForm.cptyCommission) {
        this.tradeForm.cptyCommission = Number(
          this.tradeForm.cptyCommission
        ).toFixed(2);
      }

      if (this.tradeForm.amount) {
        this.tradeForm.amount = Number(this.tradeForm.amount).toFixed(8);
      }

      if (this.tradeForm.counterpartyAmount) {
        this.tradeForm.counterpartyAmount = Number(
          this.tradeForm.counterpartyAmount
        ).toFixed(2);
      }
      if (this.tradeForm.clientAmount) {
        this.tradeForm.clientAmount = Number(
          this.tradeForm.clientAmount
        ).toFixed(2);
      }
      if (this.tradeForm.takeProfitLimit) {
        this.tradeForm.takeProfitLimit = Number.parseFloat(
          this.tradeForm.takeProfitLimit
        ).toFixed(8);
      }
      if (this.tradeForm.stopLossLimit) {
        this.tradeForm.stopLossLimit = Number.parseFloat(
          this.tradeForm.stopLossLimit
        ).toFixed(8);
      }
      if (this.tradeForm.stopLossStop) {
        this.tradeForm.stopLossStop = Number.parseFloat(
          this.tradeForm.stopLossStop
        ).toFixed(8);
      }

      if (this.tradeForm.cptyTakeProfitLimit) {
        this.tradeForm.cptyTakeProfitLimit = Number.parseFloat(
          this.tradeForm.cptyTakeProfitLimit
        ).toFixed(8);
      }
      if (this.tradeForm.cptyStopLossLimit) {
        this.tradeForm.cptyStopLossLimit = Number.parseFloat(
          this.tradeForm.cptyStopLossLimit
        ).toFixed(8);
      }
      if (this.tradeForm.cptyStopLossStop) {
        this.tradeForm.cptyStopLossStop = Number.parseFloat(
          this.tradeForm.cptyStopLossStop
        ).toFixed(8);
      }

      if (this.tradeForm.stopPrice) {
        this.tradeForm.stopPrice = Number(this.tradeForm.stopPrice).toFixed(8);
      }

      if (this.tradeForm.trailingAmount) {
        this.tradeForm.trailingAmount = Number(
          this.tradeForm.trailingAmount
        ).toFixed(2);
      }
      if (this.tradeForm.profit) {
        this.tradeForm.profit = Number(this.tradeForm.profit).toFixed(2);
      }
    },
    setTradeData() {
      if (
        this.tradeForm.timeInForce == "GTC" ||
        this.tradeForm.timeInForce == "FOK" ||
        this.tradeForm.timeInForce == "FAK"
      ) {
        this.tradeForm.orderDurationDate = null;
        this.tradeForm.orderDurationTime = null;
      }

      if (this.tradeForm.tax != null) {
        this.tradeForm.tax = Number(this.tradeForm.tax);
      }

      if (!this.tradeForm.enableConnectedOCOOrders) {
        this.tradeForm.takeProfitLimit = null;
        this.tradeForm.stopLossStop = null;
        this.tradeForm.stopLossLimit = null;
        this.tradeForm.cptyTakeProfitLimit = null;
        this.tradeForm.cptyStopLossStop = null;
        this.tradeForm.cptyStopLossLimit = null;
      }

      const self = this;
      let setTrade = {
        costCenter: self.tradeForm.costCenter,

        instrumentType: "EQ",
        tradeDate:
          self.tradeForm.tradeDate != null
            ? self.YMDdateFormat(self.tradeForm.tradeDate)
            : null,
        settlementDate:
          self.tradeForm.settlementDate != null
            ? self.YMDdateFormat(self.tradeForm.settlementDate)
            : null,
        orderTime:
          self.tradeForm.orderTime != null
            ? self.timeFormat(self.tradeForm.orderTime)
            : null,
        executionTime:
          self.tradeForm.executionTime != null
            ? self.timeFormat(self.tradeForm.executionTime)
            : null,
        client: self.tradeForm.client,
        account: self.tradeForm.clientAccount,
        securityDesc: self.tradeForm.securityDesc,
        isin: self.tradeForm.isin,
        buySell: self.tradeForm.buySell,
        quantity: self.tradeForm.quantity != null ? self.tradeForm.quantity : 0,
        clientAmount:
          self.tradeForm.clientAmount != null
            ? parseFloat(self.tradeForm.clientAmount)
            : null,
        counterpartyRate:
          self.tradeForm.counterpartyRate != null
            ? parseFloat(self.tradeForm.counterpartyRate)
            : null,
        comissionRate:
          self.tradeForm.clientRate != null
            ? parseFloat(self.tradeForm.clientRate)
            : null,
        counterpartyComission:
          self.tradeForm.cptyCommission != null
            ? parseFloat(self.tradeForm.cptyCommission)
            : null,
        commissionAmount:
          self.tradeForm.clientCommission != null
            ? parseFloat(self.tradeForm.clientCommission)
            : null,
        counterpartyAmount:
          self.tradeForm.counterpartyAmount != null
            ? parseFloat(self.tradeForm.counterpartyAmount)
            : null,
        counterpartyAccount: self.tradeForm.cptyAccount,
        counterpartyTrader: self.tradeForm.counterpartyTrader,
        ccy: self.tradeForm.clientPriceType,
        price:
          self.tradeForm.clientPrice != null
            ? parseFloat(self.tradeForm.clientPrice)
            : null,
        amount:
          self.tradeForm.amount != null
            ? parseFloat(self.tradeForm.amount)
            : null,
        counterparty: self.tradeForm.counterparty,
        tcUser: self.tradeForm.scfUser,
        orderTakenVia: self.tradeForm.orderTakenVia,
        orderGivenThrough: self.tradeForm.orderGivenThrough,
        orderPrice: null,
        margin: self.tradeForm.tradeReportingNeeded ? 1 : 0,
        exchangeTraded: self.tradeForm.exchangeTraded,
        prop: self.tradeForm.prop ? 1 : 0,
        savedBy: self.user.name ? self.user.name : null,
        saveDate: moment().format("YYYY-MM-DD"),
        saveTime: moment().format("HH:mm:ss"),
        canceled: 0,
        canceledBy: null,
        canceledDate: null,
        canceledTime: null,
        canceledReason: null,
        traderNote: self.tradeForm.note,
        securityAccount: null,
        actingCapaticy: self.tradeForm.actingCapaticy,
        // tax: self.tradeForm.tax != null ? parseFloat(self.tradeForm.tax) : null,
        tax: null,
        tcUti: self.tradeForm.uti,
        uniqueLinkId: self.tradeForm.uniqueLinkID,
        tcProfit:
          self.tradeForm.profit != null
            ? parseFloat(self.tradeForm.profit)
            : null,
        entryType: self.tradeForm.entryType,
        orderType: self.tradeForm.orderType,
        timeInForce: self.tradeForm.timeInForce,
        stopPrice:
          self.tradeForm.stopPrice != null
            ? parseFloat(self.tradeForm.stopPrice)
            : null,
        trailingAmount:
          self.tradeForm.trailingAmount != null
            ? parseFloat(self.tradeForm.tradeForm)
            : null,
        takeProfit: null,
        stopLoss:
          self.tradeForm.stopLossStop != null
            ? parseFloat(self.tradeForm.stopLossStop)
            : null,
        orderDate:
          self.tradeForm.orderDate != null
            ? self.YMDdateFormat(self.tradeForm.orderDate)
            : null,
        orderDurationDate:
          self.tradeForm.orderDurationDate != null
            ? self.YMDdateFormat(self.tradeForm.orderDurationDate)
            : null,
        orderDurationTime:
          self.tradeForm.orderDurationTime != null
            ? self.timeFormat(self.tradeForm.orderDurationTime)
            : null,
        enableOCO:
          self.tradeForm.enableConnectedOCOOrders != null
            ? self.tradeForm.enableConnectedOCOOrders
            : false,
        ocoType: null,
        takeProfitLimit:
          self.tradeForm.takeProfitLimit != null
            ? parseFloat(self.tradeForm.takeProfitLimit)
            : null,
        stopLossLimit:
          self.tradeForm.stopLossLimit != null
            ? parseFloat(self.tradeForm.stopLossLimit)
            : null,
        ocoLink: null,
        orderQuantity: null,
        future4: null,
        future5: null,
        future6: null,
        future7: null,
        future8: null,
        future9: null,
        future10: null,
        future11: null,
        future12: null,
        future13: null,
        future14: null,
        future15: null,
        future16: null,
        ocoLink: self.tradeForm.ocoLink ?? null,
        ocoType: self.tradeForm.ocoType ?? null,
        reversedBy: self.user.name,
        reversedDateTime: moment().format("YYYY-MM-DD HH:mm:ss"),
        ID: self.tradeForm.tradeRef,
      };

      return setTrade;
    },
  },
  watch: {
    reverseEntryTradeModal: {
      handler: function(val, before) {
        if (val) {
          this.getArangeEquityBondTCUsers();
          //   this.getArrangeEquityBondCurrencies();
          this.getArrangeEquityBondOrderTakenGivenThrough();
        }
      },
    },
    fetchedTradeData: {
      handler: function(val, before) {
        if (val.ID != 0) {
          this.tradeForm = {
            costCenter: val.CostCenter,

            orderDurationDate:
              val.OrderDurationDate != null
                ? this.dateFormat(val.OrderDurationDate)
                : null,
            orderDurationTime:
              val.OrderDurationTime != null ? val.OrderDurationTime : null,
            entryType: val.EntryType ?? null,
            buySell: val.BuySell ?? null,
            orderType: val.OrderType ?? null,
            timeInForce: val.TimeinForce ?? null,
            orderDate:
              val.OrderDate != null ? this.dateFormat(val.OrderDate) : null,
            orderTime: val.OrderTime != null ? val.OrderTime : null,
            enableConnectedOCOOrders: val.EnableOCO ? true : false,
            stopPrice: val.StopPrice != null ? parseFloat(val.StopPrice) : null,
            trailingAmount:
              val.TrailingAmount != null
                ? parseFloat(val.TrailingAmount)
                : null,
            takeProfitLimit:
              val.TakeProfitLimit != null
                ? parseFloat(val.TakeProfitLimit)
                : null,
            stopLossStop:
              val.StopLoss != null ? parseFloat(val.StopLoss) : null,
            stopLossLimit:
              val.StopLossLimit != null ? parseFloat(val.StopLossLimit) : null,
            cptyTakeProfitLimit:
              val.CptyTakeProfitLimit != null
                ? parseFloat(val.CptyTakeProfitLimit)
                : null,
            cptyStopLossStop:
              val.CptyStopLoss != null ? parseFloat(val.CptyStopLoss) : null,
            cptyStopLossLimit:
              val.CptyStopLossLimit != null
                ? parseFloat(val.CptyStopLossLimit)
                : null,
            instrumentType: "EQ",
            securityDesc: val.SecurityDescription ?? null,
            quantity: val.Quantity ?? null,
            clientPrice: val.Price != null ? parseFloat(val.Price) : null,
            clientPriceType: val.Ccy ?? null,
            amount: val.Amount != null ? parseFloat(val.Amount) : null,
            client: val.Client ?? null,
            clientAccount: val.Account ?? null,
            isin: val.Isin ?? null,
            exchangeTraded: val.ExchangeTraded ?? null,
            counterparty: val.Counterparty ?? null,
            cptyAccount: val.CounterpartyAccount ?? null,
            counterpartyTrader: val.CounterpartyTrader ?? null,
            scfUser: val.TcUser ?? null,
            actingCapaticy: val.ActingCapacity ?? null,
            tradeReportingNeeded: val.margin == "0" ? false : true,
            tradeDate:
              val.TradeDate != null ? this.dateFormat(val.TradeDate) : null,
            settlementDate:
              val.SettlementDate != null
                ? this.dateFormat(val.SettlementDate)
                : null,
            executionTime: val.ExecutionTime != null ? val.ExecutionTime : null,
            clientRate:
              val.CommissionRate != null
                ? parseFloat(val.CommissionRate)
                : null,
            clientCommission:
              val.CommissionAmount != null
                ? parseFloat(val.CommissionAmount)
                : null,
            clientAmount:
              val.ClientAmount != null ? parseFloat(val.ClientAmount) : null,
            counterpartyRate:
              val.CounterpartyRate != null
                ? parseFloat(val.CounterpartyRate)
                : null,
            tax: val.Tax != null ? val.Tax : null,
            cptyCommission:
              val.CounterpartyCommission != null
                ? parseFloat(val.CounterpartyCommission)
                : null,
            counterpartyAmount:
              val.CounterpartyAmount != null
                ? parseFloat(val.CounterpartyAmount)
                : null,
            orderTakenVia: val.OrderTakenVia ?? null,
            orderGivenThrough: val.OrderGivenThrough ?? null,
            profit: val.TcProfit != null ? parseFloat(val.TcProfit) : null,
            tradeReportingNeeded: val.TradeReportingNeeded == 1 ? true : false,
            note: val.TraderNote ?? null,
            calculateCommissionFromRate1:
              val.CalculateCommissionFromRate1 == 1 ? true : false,
            calculateCommissionFromRate2:
              val.CalculateCommissionFromRate2 == 1 ? true : false,
            uti: val.TcUti ?? null,
            uniqueLinkID: val.UniqueLinkId ?? null,
            tradeRef: val.id,
            tradeStart: null,
            tradeEnd: null,
            settleStart: null,
            settleEnd: null,
            ocoLink: val.OCOLink ?? null,
            ocoType: val.OCOType ?? null,
          };

          this.changeTimeInForceType();

          this.arrangeInputFormat();

          // hack textare row count
          setTimeout(function() {
            let textarea = document.getElementById("form-textarea");
            if (textarea) {
              textarea.rows = 1;
            }
          }, 200);
        }
      },
    },
  },
};
</script>

<style>
#tradeModal .modal-header .modal-title {
  color: white !important;
}

.eq_color .vs__dropdown-toggle .vs__selected-options .vs__selected {
  color: #191970;
}

.fi_color .vs__dropdown-toggle .vs__selected-options .vs__selected {
  color: #cd853f;
}

#actions-popover {
  width: 500px !important;
  max-width: 500px !important;
}
</style>

<style lang="scss">
@import "@core/scss/vue/libs/vue-select.scss";
@import "@core/scss/vue/libs/vue-flatpicker.scss";
</style>

<!-- compact design scss code -->

<style lang="scss" scoped>
@import "../../assets/scss/compact-design.scss";

// .v-select::v-deep .vs__selected {
//     position: relative !important;
//   }
</style>
