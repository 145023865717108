import axiosIns from "@/libs/axios";

export default {
  namespaced: true,
  state: {},
  getters: {},
  mutations: {},
  actions: {
    // get all verified client accounts
    getAllAccounts(ctx) {
      return new Promise((resolve, reject) => {
        axiosIns
          .post(`refreshPortfolioForClientAccountsVerified`)
          .then((response) => {
            resolve(response);
          })
          .catch((error) => reject(error));
      });
    },

    getAccountCostCenter(ctx, data) {
      return new Promise((resolve, reject) => {
        axiosIns
          .post(`getAccountCostCenter`, data)
          .then((response) => {
            resolve(response);
          })
          .catch((error) => reject(error));
      });
    },

    // Arange TC users
    fetchArangeEquityBondTCUsers(ctx, user) {
      return new Promise((resolve, reject) => {
        axiosIns
          .post(`getArangeEquityTCUsers`, user)
          .then((response) => {
            resolve(response);
          })
          .catch((error) => reject(error));
      });
    },
    //Equity Order Taken Given Through
    fetchArrangeEquityBondOrderTakenGivenThrough(ctx, user) {
      return new Promise((resolve, reject) => {
        axiosIns
          .post(`getArrangeEquityOrderTakenGivenThrough`, user)
          .then((response) => {
            resolve(response);
          })
          .catch((error) => reject(error));
      });
    },
    //Equity Currencies
    fetchArrangeEquityBondCurrencies(ctx, user) {
      return new Promise((resolve, reject) => {
        axiosIns
          .post(`getArrangeEquityCurrencies`, user)
          .then((response) => {
            resolve(response);
          })
          .catch((error) => reject(error));
      });
    },
    //Search Equity
    searchEquityBond(ctx, searchData) {
      return new Promise((resolve, reject) => {
        axiosIns
          .post(`searchEquity`, searchData)
          .then((response) => {
            resolve(response);
          })
          .catch((error) => reject(error));
      });
    },

    saveSearch(ctx, searchData) {
      return new Promise((resolve, reject) => {
        axiosIns
          .post(`saveSearch`, searchData)
          .then((response) => {
            resolve(response);
          })
          .catch((error) => reject(error));
      });
    },

    getLastSearch(ctx, data) {
      return new Promise((resolve, reject) => {
        axiosIns
          .post(`getLastSearch`, data)
          .then((response) => {
            resolve(response);
          })
          .catch((error) => reject(error));
      });
    },

    getSavedSearches(ctx, searchData) {
      return new Promise((resolve, reject) => {
        axiosIns
          .post(`getSavedSearches`, searchData)
          .then((response) => {
            resolve(response);
          })
          .catch((error) => reject(error));
      });
    },

    deleteSavedSearch(ctx, searchData) {
      return new Promise((resolve, reject) => {
        axiosIns
          .post(`deleteSavedSearch`, searchData)
          .then((response) => {
            resolve(response);
          })
          .catch((error) => reject(error));
      });
    },
    deleteSavedDefault(ctx, searchData) {
      return new Promise((resolve, reject) => {
        axiosIns
          .post(`deleteSavedDefault`, searchData)
          .then((response) => {
            resolve(response);
          })
          .catch((error) => reject(error));
      });
    },

    //Last Search Equity
    getLastSearchEquity(ctx, searchData) {
      return new Promise((resolve, reject) => {
        axiosIns
          .post(`getLastSearchEquity`, searchData)
          .then((response) => {
            resolve(response);
          })
          .catch((error) => reject(error));
      });
    },

    //filter Trade Date
    fetchFilterTradeDate(ctx, date) {
      return new Promise((resolve, reject) => {
        axiosIns
          .post(`getFilterEquityTradeDate`, date)
          .then((response) => {
            resolve(response);
          })
          .catch((error) => reject(error));
      });
    },
    fetchFilterSettlementDate(ctx, date) {
      return new Promise((resolve, reject) => {
        axiosIns
          .post(`getFilterEquitySettlementDate`, date)
          .then((response) => {
            resolve(response);
          })
          .catch((error) => reject(error));
      });
    },

    getViewSelectedTrade(ctx, trade) {
      return new Promise((resolve, reject) => {
        axiosIns
          .post(`getViewSelectedEquityTrade`, trade)
          .then((response) => {
            resolve(response);
          })
          .catch((error) => reject(error));
      });
    },
    getViewAmendedSelectedTrade(ctx, trade) {
      return new Promise((resolve, reject) => {
        axiosIns
          .post(`getViewAmendedSelectedEquityTrade`, trade)
          .then((response) => {
            resolve(response);
          })
          .catch((error) => reject(error));
      });
    },
    createNewEntry(ctx, trade) {
      return new Promise((resolve, reject) => {
        axiosIns
          .post(`createNewEquityEntry`, trade)
          .then((response) => {
            resolve(response);
          })
          .catch((error) => reject(error));
      });
    },
    checkTradeVerifyOperation(ctx, trade) {
      return new Promise((resolve, reject) => {
        axiosIns
          .post(`checkTradeVerifyOperation`, trade)
          .then((response) => {
            resolve(response);
          })
          .catch((error) => reject(error));
      });
    },
    checkTradeUserOperation(ctx, trade) {
      return new Promise((resolve, reject) => {
        axiosIns
          .post(`checkEquityTradeUserOperation`, trade)
          .then((response) => {
            resolve(response);
          })
          .catch((error) => reject(error));
      });
    },
    getVerifySelectedTrade(ctx, trade) {
      return new Promise((resolve, reject) => {
        axiosIns
          .post(`getVerifySelectedEquityTrade`, trade)
          .then((response) => {
            resolve(response);
          })
          .catch((error) => reject(error));
      });
    },
    tradeVerifyOperation(ctx, trade) {
      return new Promise((resolve, reject) => {
        axiosIns
          .post(`equityTradeVerifyOperation`, trade)
          .then((response) => {
            resolve(response);
          })
          .catch((error) => reject(error));
      });
    },
    checkTCUtiJournalCreated(ctx, trade) {
      return new Promise((resolve, reject) => {
        axiosIns
          .post(`checkTCUtiJournalCreated`, trade)
          .then((response) => {
            resolve(response);
          })
          .catch((error) => reject(error));
      });
    },
    getAmendSelectedTrade(ctx, trade) {
      return new Promise((resolve, reject) => {
        axiosIns
          .post(`getAmendSelectedEquityTrade`, trade)
          .then((response) => {
            resolve(response);
          })
          .catch((error) => reject(error));
      });
    },
    getCopySelectedTrade(ctx, trade) {
      return new Promise((resolve, reject) => {
        axiosIns
          .post(`getCopySelectedEquityTrade`, trade)
          .then((response) => {
            resolve(response);
          })
          .catch((error) => reject(error));
      });
    },

    checkAmendEntryType(ctx, trade) {
      return new Promise((resolve, reject) => {
        axiosIns
          .post(`checkAmendEntryType`, trade)
          .then((response) => {
            resolve(response);
          })
          .catch((error) => reject(error));
      });
    },

    checkUpdateAmendment(ctx, trade) {
      return new Promise((resolve, reject) => {
        axiosIns
          .post(`checkEquityUpdateAmendment`, trade)
          .then((response) => {
            resolve(response);
          })
          .catch((error) => reject(error));
      });
    },
    removeTradeVerifyOperation(ctx, trade) {
      return new Promise((resolve, reject) => {
        axiosIns
          .post(`removeTradeVerifyOperation`, trade)
          .then((response) => {
            resolve(response);
          })
          .catch((error) => reject(error));
      });
    },
    tradeAmendmentUpdate(ctx, trade) {
      return new Promise((resolve, reject) => {
        axiosIns
          .post(`equityTradeAmendmentUpdate`, trade)
          .then((response) => {
            resolve(response);
          })
          .catch((error) => reject(error));
      });
    },
    tradeAmendmentUpdateOCO(ctx, trade) {
      return new Promise((resolve, reject) => {
        axiosIns
          .post(`equityTradeAmendmentUpdateOCO`, trade)
          .then((response) => {
            resolve(response);
          })
          .catch((error) => reject(error));
      });
    },
    copySelectedTrade(ctx, trade) {
      return new Promise((resolve, reject) => {
        axiosIns
          .post(`copySelectedEquityTrade`, trade)
          .then((response) => {
            resolve(response);
          })
          .catch((error) => reject(error));
      });
    },

    /// journal section

    checkJournalTradeVerifyOperation(ctx, trade) {
      return new Promise((resolve, reject) => {
        axiosIns
          .post(`checkJournalTradeVerifyOperation`, trade)
          .then((response) => {
            resolve(response);
          })
          .catch((error) => reject(error));
      });
    },
    equityTradeconfirmation(ctx, trade) {
      return new Promise((resolve, reject) => {
        axiosIns
          .post(`equityTradeconfirmation`, trade, { responseType: "blob" })
          .then((response) => {
            resolve(response);
          })
          .catch((error) => reject(error));
      });
    },
    getCreateJournalTrade(ctx, trade) {
      return new Promise((resolve, reject) => {
        axiosIns
          .post(`getCreateEquityJournalTrade`, trade)
          .then((response) => {
            resolve(response);
          })
          .catch((error) => reject(error));
      });
    },
    checkJournalOperations(ctx, trade) {
      return new Promise((resolve, reject) => {
        axiosIns
          .post(`checkEquityJournalOperations`, trade)
          .then((response) => {
            resolve(response);
          })
          .catch((error) => reject(error));
      });
    },
    createJournalEntry(ctx, journal) {
      return new Promise((resolve, reject) => {
        axiosIns
          .post(`createEquityJournalEntry`, journal)
          .then((response) => {
            resolve(response);
          })
          .catch((error) => reject(error));
      });
    },
    equityTradeDvpOperations(ctx, trade) {
      return new Promise((resolve, reject) => {
        axiosIns
          .post(`equityTradeDvpOperations`, trade)
          .then((response) => {
            resolve(response);
          })
          .catch((error) => reject(error));
      });
    },
    createEquityDVPJournalEntry(ctx, journal) {
      return new Promise((resolve, reject) => {
        axiosIns
          .post(`createEquityDVPJournalEntry`, journal)
          .then((response) => {
            resolve(response);
          })
          .catch((error) => reject(error));
      });
    },
    getViewJournalTrade(ctx, journal) {
      return new Promise((resolve, reject) => {
        axiosIns
          .post(`getViewEquityJournalTrade`, journal)
          .then((response) => {
            resolve(response);
          })
          .catch((error) => reject(error));
      });
    },
    getCanceledEquityBondById(ctx, trade) {
      return new Promise((resolve, reject) => {
        axiosIns
          .post(`getCanceledEquityById`, trade)
          .then((response) => {
            resolve(response);
          })
          .catch((error) => reject(error));
      });
    },
    checkTCUtiReserved(ctx, trade) {
      return new Promise((resolve, reject) => {
        axiosIns
          .post(`checkTCUtiReserved`, trade)
          .then((response) => {
            resolve(response);
          })
          .catch((error) => reject(error));
      });
    },

    checkReversalInitiated(ctx, trade) {
      return new Promise((resolve, reject) => {
        axiosIns
          .post(`checkReversalInitiated`, trade)
          .then((response) => {
            resolve(response);
          })
          .catch((error) => reject(error));
      });
    },

    getAmendInitialise(ctx, trade) {
      return new Promise((resolve, reject) => {
        axiosIns
          .post(`getEquityAmendInitialise`, trade)
          .then((response) => {
            resolve(response);
          })
          .catch((error) => reject(error));
      });
    },

    tradeInitiateReversal(ctx, trade) {
      return new Promise((resolve, reject) => {
        axiosIns
          .post(`equityTradeInitiateReversal`, trade)
          .then((response) => {
            resolve(response);
          })
          .catch((error) => reject(error));
      });
    },

    getReverseEntry(ctx, trade) {
      return new Promise((resolve, reject) => {
        axiosIns
          .post(`getReverseEquityEntry`, trade)
          .then((response) => {
            resolve(response);
          })
          .catch((error) => reject(error));
      });
    },

    reverseEntry(ctx, trade) {
      return new Promise((resolve, reject) => {
        axiosIns
          .post(`reverseEquityEntry`, trade)
          .then((response) => {
            resolve(response);
          })
          .catch((error) => reject(error));
      });
    },

    getUndoInitiateReversal(ctx, trade) {
      return new Promise((resolve, reject) => {
        axiosIns
          .post(`getUndoInitiateEquityReversal`, trade)
          .then((response) => {
            resolve(response);
          })
          .catch((error) => reject(error));
      });
    },

    undoInitiateReversal(ctx, trade) {
      return new Promise((resolve, reject) => {
        axiosIns
          .post(`undoInitiateEquityReversal`, trade)
          .then((response) => {
            resolve(response);
          })
          .catch((error) => reject(error));
      });
    },

    getAmendDetailDatas(ctx, id) {
      return new Promise((resolve, reject) => {
        axiosIns
          .post(`getAmendEquityDetailDatas`, id)
          .then((response) => {
            resolve(response);
          })
          .catch((error) => reject(error));
      });
    },

    selectTexRateAsExchangeBuySell(ctx, exchange) {
      return new Promise((resolve, reject) => {
        axiosIns
          .post(`selectEquityTexRateAsExchangeBuySell`, exchange)
          .then((response) => {
            resolve(response);
          })
          .catch((error) => reject(error));
      });
    },

    //get holidays
    getHolidays(ctx, user) {
      return new Promise((resolve, reject) => {
        axiosIns
          .post(`getHolidays`, user)
          .then((response) => {
            resolve(response);
          })
          .catch((error) => reject(error));
      });
    },

    //send email
    sendInitiateReversalMail(ctx, trade) {
      return new Promise((resolve, reject) => {
        axiosIns
          .post(`sendInitiateReversalMail`, trade)
          .then((response) => {
            resolve(response);
          })
          .catch((error) => reject(error));
      });
    },

    //get Last 7 Days Equity Trades
    getLast7DaysEquityTrades(ctx) {
      return new Promise((resolve, reject) => {
        axiosIns
          .get(`getLast7DaysTradesEquity`)
          .then((response) => {
            resolve(response);
          })
          .catch((error) => reject(error));
      });
    },

    //save Default Equity Entry.
    saveDefaultEntry(ctx, data) {
      return new Promise((resolve, reject) => {
        axiosIns
          .post(`saveDefaultEntry`, data)
          .then((response) => {
            resolve(response);
          })
          .catch((error) => reject(error));
      });
    },

    //get Saved Default Entries
    getSavedDefaultEntries(ctx, filter) {
      return new Promise((resolve, reject) => {
        axiosIns
          .post(`getSavedDefaultEntries`, filter)
          .then((response) => {
            resolve(response);
          })
          .catch((error) => reject(error));
      });
    },

    //Fx spot forward swap Order Taken Given Through
    fetchArrangeFxExecutedOrderTakenGivenThrough(ctx, user) {
      return new Promise((resolve, reject) => {
        axiosIns
          .post(`getArrangeFxExecutedOrderTakenGivenThrough`, user)
          .then((response) => {
            resolve(response);
          })
          .catch((error) => reject(error));
      });
    },

    //trade Multiple Change Equity Status Opr.
    tradeMultipleChangeEquityStatusOpr(ctx, data) {
      return new Promise((resolve, reject) => {
        axiosIns
          .post(`equityTradesMultipleChangeStatusOpr`, data)
          .then((response) => {
            resolve(response);
          })
          .catch((error) => reject(error));
      });
    },

    //trade Journal with multiple existing
    tradeJournalEquityWithExisting(ctx, data) {
      return new Promise((resolve, reject) => {
        axiosIns
          .post(`tradeJournalEquityWithExisting`, data)
          .then((response) => {
            resolve(response);
          })
          .catch((error) => reject(error));
      });
    },

    //trade Multiple Select Transfer Copy Opr
    createExistingEquityJournalEntry(ctx, data) {
      return new Promise((resolve, reject) => {
        axiosIns
          .post(`createExistingEquityJournalEntry`, data)
          .then((response) => {
            resolve(response);
          })
          .catch((error) => reject(error));
      });
    },

    //trade Multiple Select Equity Copy Opr.
    tradeMultipleSelectEquityCopyOpr(ctx, data) {
      return new Promise((resolve, reject) => {
        axiosIns
          .post(`equityTradeMultipleSelectCopyOpr`, data)
          .then((response) => {
            resolve(response);
          })
          .catch((error) => reject(error));
      });
    },

    exportXlsx(ctx, data) {
      return new Promise((resolve, reject) => {
        axiosIns
          //.get("user", { params: queryParams })
          .post("exportEquityTradesXlsx", data, { responseType: "blob" })
          .then((response) => {
            resolve(response);
          })

          .catch((error) => reject(error));
      });
    },

    exportCsv(ctx, data) {
      return new Promise((resolve, reject) => {
        axiosIns
          //.get("user", { params: queryParams })
          .post("exportEquityTradesCsv", data, { responseType: "blob" })
          .then((response) => {
            resolve(response);
          })

          .catch((error) => reject(error));
      });
    },
  },
};
